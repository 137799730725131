<template>
  <div class="detail">
    <div class="detail-top">
      <slot></slot>
    </div>
    <div class="detail-content">
      <table class="detail-table">
        <colgroup>
          <col width="140">
          <col>
        </colgroup>
        <template v-for="[field, option] in Object.entries(meta.field)">
          <thead v-if="option.title"
                 :key="`detail-${field}`">
            <tr>
              <td colspan="2"></td>
            </tr>
            <tr>
              <th colspan="2"
                  v-html="option.name"></th>
            </tr>
          </thead>
          <template v-else>
            <tr v-if="!option.hidden"
                :key="`detail-${field}`"
                :class="{ inner: ['list', 'tab'].includes(option.type.split('|')[0]),
                          disabled: editMode && !option.editable,
                          editing: editMode && option.editable }">
              <th v-if="option.name"
                  v-html="option.name"></th>
              <td :colspan="option.name ? null : 2">
                <ui-data-input v-if="editMode && option.editable"
                               :type="option.type"
                               :label="null"
                               :options="option.options"
                               :update="option.update"
                               :required="option.required"
                               :field="option.field || field"
                               :model="data"
                               :value="data[option.field || field]"
                               :error="error[option.field || field]"
                               @error="sendError"
                               @input="$emit('input', (option.field || field), $event)" />

                <ui-data v-else
                         :type="option.type"
                         :value="data[option.field || field]"
                         :model="data"
                         :options="option"
                         :option="option.option"
                         :listData="option.options"
                         :sortBy="tableSortBy[option.field || field] || ''"
                         @setTableData="(...args) => { setTableData((option.field || field), ...args) }"
                         @changeTableSort="(...args) => { changeTableSort((option.field || field), ...args) }"
                         @button="button" />
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { hasKey } from '@/utils/tools'

import UiData from '@/components/_ui/UiData'
import UiDataInput from '@/components/_ui/UiDataInput'

export default {
  name: 'UiDetail',
  components: {
    UiData,
    UiDataInput
  },
  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          field: {}
        }
      },
      validator: (value) => {
        return hasKey(value, ['field'])
      },
      required: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    error: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    editMode: {
      type: Boolean,
      default: false,
      required: false
    },
    tableSortBy: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  methods: {
    sendData (data) {
      this.$emit('set', data)
    },
    sendError (key, message) {
      this.$emit('error', key, message)
    },
    setTableData (table, field, idx, data) {
      this.$emit('setTableData', table, field, idx, data)
    },
    changeTableSort (table, sort) {
      this.$emit('changeTableSort', table, sort)
    },
    button (value, data) {
      this.$emit('button', value, data)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail-top {
    height: 31.64px;
    padding: 20px;
    text-align: right;
    border-bottom: 1px solid $light;
  }

  .detail-content {
    padding: 0 15px 19px;
    height: calc(100vh - 91.64px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      box-shadow: inset 0 0 10px 10px $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $gray;
    }

    .detail-table {
      width: 100%;
      table-layout: fixed;

      th {
        padding: 5px 10px;
        text-align: left;
        width: 120px;
        border-bottom: 1px solid $light;
        transition: background-color 0.3s ease;
      }

      td {
        padding: 10px;
        line-height: 1.4;
        border-bottom: 1px solid $light;
        background: $white;
        height: 1.4em;
        transition: background-color 0.3s ease;

        .type1image {
          display: block;
          margin: -11px -10px -10px;
          background: $dimlight;

          img {
            margin-bottom: -4px;
          }
        }

        .type2code {
          display: block;
          margin: -10px;
        }
      }

      tr.disabled {
        th,
        td {
          opacity: 0.5;
          background:transparent;
        }
      }

      tr.editing {
        td {
          padding: 15px 10px 0;
        }
      }

      tr:not(.inner):not(.disabled):hover {
        th,
        td {
          background-color: $dim;
        }
      }

      thead {
        th {
          padding: 15px;
          border-bottom: none;
          border-radius: 10px;
          background-image: $black-gradient;
          color: $white;
          font-weight: bold;
        }

        td {
          padding: 0;
          background: transparent;
          border-bottom: none;
          height: 19px;
        }
      }
    }
  }
}
</style>
