<template>
  <div class="user-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          :searchUpdated="false"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail">

<!--      <ui-button :color="'purple'"-->
<!--                 @click="toggleExcel(true)">-->
<!--        <i class="xi-view-list"></i> 엑셀 다운로드-->
<!--      </ui-button>-->
    </template-search-list>
  </div>
</template>

<script>

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { EventBus } from '@/utils/event-bus'
import { getGuideList } from '../../../../api/guide'
import { NODE_ENV } from '../../../../utils/constants'

export default {
  name: 'GuideList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    UiButton
  },
  methods: {
    showDetail (data) {
      let domain = 'https://staging-forum.beusable.net'
      if (NODE_ENV === 'production') {
        domain = 'https://forum.beusable.net'
      }
      window.open(domain + '/post/' + data.id)
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleChange (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.change = !this.show.change
      } else {
        this.show.change = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_user'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('user', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    }
  },
  data () {
    return {
      reload: 0,

      infoApi: getGuideList,
      apiParam: null,

      listApi: getGuideList,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        change: false
      },
      excelDownload: false,
      initialSearchFilter: {
        key: 'GUIDE'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list-wrap {
}
</style>
