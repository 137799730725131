import { request } from '@/utils/api-request'

export function getPostList (params) {
  const url = '/api/admin/post/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPostInfo (params) {
  const url = '/api/admin/post/info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removePostInfo (params) {
  const url = '/api/admin/post/remove'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editPostInfo (params) {
  const url = '/api/admin/post/edit'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPostReplyList (params) {
  const url = '/api/admin/post/reply_list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPostReplyInfo (params) {
  const url = '/api/admin/post/reply_info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removePostReplyInfo (params) {
  const url = '/api/admin/post/reply_remove'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editPostReplyInfo (params) {
  const url = '/api/admin/post/reply_edit'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getReportContentList (params) {
  const url = '/api/admin/report/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getReportContentInfo (params) {
  const url = '/api/admin/report/info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editReportContentInfo (params) {
  const url = '/api/admin/report/edit'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removeReportContentInfo (params) {
  const url = '/api/admin/report/remove'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getTopPostList (params) {
  const url = '/api/admin/top_post/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getTopPostInfo (params) {
  const url = '/api/admin/top_post/info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editTopPostInfo (params) {
  const url = '/api/admin/top_post/edit'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removeTopPostInfo (params) {
  const url = '/api/admin/top_post/remove'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
