import { render, staticRenderFns } from "./ReviewList.vue?vue&type=template&id=985de404&scoped=true"
import script from "./ReviewList.vue?vue&type=script&lang=js"
export * from "./ReviewList.vue?vue&type=script&lang=js"
import style0 from "./ReviewList.vue?vue&type=style&index=0&id=985de404&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "985de404",
  null
  
)

export default component.exports