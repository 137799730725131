import Excel from 'exceljs/dist/exceljs.min'
import { leadingZero } from '@/utils/tools'
import { saveAs } from 'file-saver'
import planStore from '@/store/modules/plan'

class ExcelExport {
  constructor () {
    this.now = new Date()
    this.workbook = new Excel.Workbook()
    this.workbook.creator = 'Eagle'
    this.workbook.created = this.now
    this.workbook.properties.date1904 = true
  }

  setExcelData (type, columnData, excelData, selectColumn, option = {}) {
    const beusPlan = planStore.state.beusPlanData
    const baPlan = planStore.state.baPlanData

    return new Promise((resolve, reject) => {
      const book = this.workbook
      const sheet = book.addWorksheet(type)
      sheet.views = [{ showGridLines: false, showRuler: false }]

      const columns = []
      columns.push({ header: '', key: '', width: 3 })

      Object.keys(columnData).forEach(data => {
        const columnItem = columnData[data]
        for (let i = 0, iLen = selectColumn.length; i < iLen; i++) {
          const item = selectColumn[i]
          if (item === columnItem.field) {
            const col = { header: columnItem.name, key: columnItem.field, width: 15 }
            columns.push(col)
          }
        }
      })
      // switch (type) {
      //   case 'service':
      //     columns = [
      //       { header: '', key: 'blank', width: 3 },
      //       { header: 'Rank', key: 'rank', width: 6.5 },
      //       { header: 'Thumbnail', key: 'img', width: 10 },
      //       { header: 'Element (CSS Selector)', key: 'selector', width: 30 },
      //       { header: 'Tag Name', key: 'tagName', width: 15 },
      //       { header: 'Position_X(px)', key: 'left', width: 15 }
      //     ]
      //     break
      // }
      sheet.columns = columns
      const data = []

      excelData.forEach(item => {
        const d = JSON.parse(JSON.stringify(item))
        if (type === 'user') {
          if (d.beusPlanNumber >= 0) {
            d.beusPlanNumber = beusPlan[d.beusPlanNumber] ? beusPlan[d.beusPlanNumber].planName + '(' + d.beusPlanNumber + ')' : ''
          }
          if (d.baPlanNumber >= 0) {
            d.baPlanNumber = baPlan[d.baPlanNumber] ? baPlan[d.baPlanNumber].planName + '(' + d.baPlanNumber + ')' : ''
          }
        }

        if (type === 'planBa') {
          if (d.planNumber >= 0) {
            d.planNumber = baPlan[d.planNumber] ? baPlan[d.planNumber].planName + '(' + d.planNumber + ')' : ''
          }
        }

        if (type === 'planBeus') {
          if (d.planNumber >= 0) {
            d.planNumber = beusPlan[d.planNumber] ? beusPlan[d.planNumber].planName + '(' + d.planNumber + ')' : ''
          }
        }

        if (d.useStartDate) {
          d.useStartDate = new Date(d.useStartDate).toLocaleString()
        }
        if (d.useEndDate) {
          d.useEndDate = new Date(d.useEndDate).toLocaleString()
        }
        if (d.regDate) {
          d.regDate = new Date(d.regDate).toLocaleString()
        }
        if (d.paymentDate) {
          d.paymentDate = new Date(d.paymentDate).toLocaleString()
        }
        if (d.lastLoginDate) {
          d.lastLoginDate = new Date(d.lastLoginDate).toLocaleString()
        }
        if (d.startDate) {
          d.startDate = new Date(d.startDate).toLocaleString()
        }
        if (d.endDate) {
          d.endDate = new Date(d.endDate).toLocaleString()
        }
        if (d.installDate) {
          d.installDate = new Date(d.installDate).toLocaleString()
        }
        if (d.userCreditEmptyDate) {
          d.userCreditEmptyDate = new Date(d.userCreditEmptyDate).toLocaleString()
        }
        if (d.domainCreditEmptyDate) {
          d.domainCreditEmptyDate = new Date(d.domainCreditEmptyDate).toLocaleString()
        }

        data.push(d)
      })
      data.push({ blank: '' })

      console.log(data)
      sheet.addRows(data)

      const styles = {
        header: {
          font: {
            size: 10,
            color: { argb: 'FFE7E6E6' }
          },
          alignment: {
            vertical: 'middle',
            horizontal: 'center'
          },
          border: {
            top: { style: 'thin', color: { argb: 'FF2F384B' } },
            left: { style: 'thin', color: { argb: 'FF2F384B' } },
            bottom: { style: 'thin', color: { argb: 'FF2F384B' } },
            right: { style: 'thin', color: { argb: 'FF2F384B' } }
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF2F384B' },
            bcColor: { argb: 'FFFFFFFF' }
          }
        },
        footer: {
          border: {
            top: { style: 'thin', color: { argb: 'FF2F384B' } },
            left: { style: 'thin', color: { argb: 'FF2F384B' } },
            bottom: { style: 'thin', color: { argb: 'FF2F384B' } },
            right: { style: 'thin', color: { argb: 'FF2F384B' } }
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF2F384B' },
            bcColor: { argb: 'FFFFFFFF' }
          }
        },
        item: {
          border: {
            top: { style: 'thin', color: { argb: 'FFE7E6E6' } },
            left: { style: 'thin', color: { argb: 'FFE7E6E6' } },
            bottom: { style: 'thin', color: { argb: 'FFE7E6E6' } },
            right: { style: 'thin', color: { argb: 'FFE7E6E6' } }
          },
          font: {
            size: 10,
            color: { argb: 'FF2F384B' }
          },
          fontRed: {
            size: 10,
            color: { argb: 'FFFF6160' }
          },
          fontBlue: {
            size: 10,
            color: { argb: 'FF8EA9DB' }
          },
          alignment: {
            vertical: 'middle',
            horizontal: 'center'
          }
        }
      }

      sheet.views = [
        { state: 'frozen', ySplit: 1 }
      ]
      sheet.eachRow(function (row, rowNumber) {
        if (rowNumber === 1) {
          row.height = 40
          row.border = styles.header.border
          row.font = styles.header.font
          row.alignment = styles.header.alignment
          row.fill = styles.header.fill
        } else if (rowNumber === sheet.rowCount) {
          row.border = styles.footer.border
          row.fill = styles.footer.fill
        } else {
          if (['scroll', 'swipe'].includes(type)) {
            row.height = 17
          } else {
            row.height = 60
          }
          row.border = styles.item.border
          row.font = styles.item.font
          row.alignment = styles.item.alignment
        }
      })

      console.log('1111')

      sheet.getColumn('A').eachCell({ includeEmpty: true }, function (cell) {
        cell.fill = null
        cell.border = null
      })

      if (['click', 'move', 'tap', 'zoom'].includes(type)) {
        sheet.getColumn('D').eachCell({ includeEmpty: true }, function (cell) {
          cell.font = styles.item.fontBlue
        })
        sheet.getColumn('F').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'F') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('G').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'G') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('H').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'H') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('I').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'I') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('J').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'J') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('K').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'K') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('L').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'L') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('M').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'M') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('N').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'N') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('O').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'O') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('P').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'P') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('Q').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'Q') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
      } else if (type === 'swipe') {
        sheet.getColumn('B').eachCell({ includeEmpty: true }, function (cell) {
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('C').eachCell({ includeEmpty: true }, function (cell) {
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('D').eachCell({ includeEmpty: true }, function (cell) {
          cell.numFmt = '#,##0'
        })
        resolve()
      } else if (type === 'scroll') {
        sheet.getColumn('C').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'C') cell.font = styles.item.fontRed
          cell.numFmt = '#,##0'
        })
        sheet.getColumn('D').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'D') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('E').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'E') cell.font = styles.item.fontRed
          cell.numFmt = '0.0'
        })
        sheet.getColumn('F').eachCell({ includeEmpty: true }, function (cell) {
          if (option.highlight === 'F') cell.font = styles.item.fontRed
        })
        resolve()
      } else {
        resolve()
      }
    })
  }

  download (fileName) {
    console.log('download')
    return new Promise((resolve, reject) => {
      fileName +=
        '_' + this.now.getFullYear() + '-' + leadingZero(this.now.getMonth() + 1) + '-' + leadingZero(this.now.getDate()) +
        'T' + leadingZero(this.now.getHours()) + '-' + leadingZero(this.now.getMinutes()) + '-' + leadingZero(this.now.getSeconds()) +
        '.xlsx'
      this.workbook.xlsx.writeBuffer()
        .then(function (data) {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          saveAs(blob, fileName)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default ExcelExport
