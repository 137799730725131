import { render, staticRenderFns } from "./UiDropdown.vue?vue&type=template&id=167bb5df&scoped=true"
import script from "./UiDropdown.vue?vue&type=script&lang=js"
export * from "./UiDropdown.vue?vue&type=script&lang=js"
import style0 from "./UiDropdown.vue?vue&type=style&index=0&id=167bb5df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167bb5df",
  null
  
)

export default component.exports