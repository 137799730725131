<template>
    <div class="user-list-wrap">
      <template-search-list :listApi="listApi"
                            :buttonOption="buttonOption"
                            :defaultSortBy="defaultSortBy"
                            :initialSearchFilter="initialSearchFilter"
                            :reload="reload"
                            :searchUpdated="false"
                            @searchFilter="getSearchFilter"
                            @showDetail="showDetail">

      </template-search-list>
      <template-detail :infoApi="infoApi"
                       :editApi="editApi"
                       :removeApi="removeApi"
                       :apiParam="apiParam"
                       :menu="'reportContent'"
                       @button="button"
                       @reload="reloadList">
      </template-detail>
    </div>
  </template>

<script>

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { EventBus } from '@/utils/event-bus'
import { getReportContentList, getReportContentInfo } from '../../../api/post'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'ReportContent',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    UiButton
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.subject) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.subject = searchData.subject
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    showDetail (data) {
      this.apiParam = { type: data.type }
      if (data.type === 'post') {
        this.apiParam.postId = data.postId
      } else {
        this.apiParam.postReplyId = data.postReplyId
      }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleChange (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.change = !this.show.change
      } else {
        this.show.change = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_user'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('user', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      const nameMap = {
        postReplyForum: 'PostReply',
        postForum: 'PostList'
      }
      const param = {}
      if (value === 'postForum') {
        param.postId = data
      } else if (value === 'postReplyForum') {
        param.postReplyId = data
      }
      localStorage.setItem(STORAGE_ITEM.searchData, JSON.stringify(param))
      const route = this.$router.resolve({ name: nameMap[value], params: param })
      window.open(route.href, '_blank')
    }
  },
  data () {
    return {
      reload: 0,
      infoApi: getReportContentInfo,
      apiParam: null,
      listApi: getReportContentList,
      removeApi: null,
      editApi: null,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '관리',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-createdAt',
      show: {
        insert: false,
        change: false
      },
      excelDownload: false,
      initialSearchFilter: {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list-wrap {
}
</style>
