<template>
  <div class="category">
    <h1>{{title}}</h1>
    <div class="list" :class="{'error': error}">
      <draggable tag="ul" :list="list" class="list-group" handle=".handle" ref="list-group">
        <li
            class="list-group-item"
            v-for="(element, index) in list"
            :key="element.name + '_' + element.seq"
            v-if="!element.deprecated"
            @click="onSelect(element)"
            @dblclick="onEdit(element, true, index);"
            :class="{'select': selectedItem && selectedItem.name == element.name && selectedItem.seq == element.seq}"
        >
          <span class="text" v-if="!element.isEdit" >{{ element.name ? element.name:'카테고리명을 입력하세요.' }}</span>
          <input :placeholder="'카테고리명을 입력하세요.'" type="text" v-show="element.isEdit" class="form-control" @keyup.enter="onEdit(element, false)" v-model="element.nameEdit" v-click-outside="onUnSelectAll" />
          <i class="xi-bars handle" v-if="listCount > 1"></i>
          <i class="xi-eye-off-o handle" v-if="depth == 2  && listCount == 1"></i>
        </li>
      </draggable>
    </div>
    <list-button :item="selectedItem" @add="onAdd" @remove="onRemove" @change="onChange"></list-button>
  </div>
</template>

<script>
import ListButton from './ListButton'
import draggable from 'vuedraggable'
import vClickOutside from 'v-click-outside'

export default {
  name: 'ListDrag',
  components: {
    ListButton,
    draggable
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: [
    'title',
    'list',
    'depth',
    'parent',
    'error'
  ],
  data () {
    return {
      selectedItem: null,
      listCount: 0,
      isDisabled: null
    }
  },
  watch: {
    async parent () {
      // this.$emit('select', this.selectedItem, this.depth)
      this.selectedItem = null
      if (this.depth !== 3) {
        this.selectedItem = this.list[0]
      }
      await this.getListCount()
    },
    list: {
      deep: true,
      async handler () {
        await this.getListCount()
      }
    }
  },
  async created () {
    await this.getListCount()
    setTimeout(() => {
      if (this.depth === 1 && this.list[0]) {
        this.onSelect(this.list[0])
      }
    }, 500)
  },
  methods: {
    getListCount () {
      const list = this.list

      let deprecatedCount = 0
      list.forEach((item) => {
        if (!item.deprecated) {
          deprecatedCount++
        }
      })

      this.listCount = deprecatedCount
    },
    onEdit (item, state, index) {
      const list = this.list

      if (this.depth === 2 && this.listCount === 1) {
        return false
      }
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const listItem = list[i]
        listItem.isEdit = false
        if (listItem.nameEdit) {
          listItem.name = listItem.nameEdit
        }
      }

      item.isEdit = state

      if (item.isEdit) {
        setTimeout(() => {
          if (this.$refs['list-group'].$el && this.$refs['list-group'].$el.querySelectorAll('input') && this.$refs['list-group'].$el.querySelectorAll('input').length > 0) {
            this.$refs['list-group'].$el.querySelectorAll('input')[index].focus()
          }
        }, 100)
        item.nameEdit = item.name
      } else {
        item.name = item.nameEdit
      }
      this.$emit('error', this.depth, false)
    },
    onSelect (item, type) {
      this.selectedItem = item
      this.$emit('select', item, this.depth, type)
    },
    onUnSelectAll () {
      this.list.forEach(item => {
        item.isEdit = false
        if (item.nameEdit) {
          item.name = item.nameEdit
        }
      })
    },
    onAdd () {
      if (this.depth !== 1 && !this.parent) {
        alert('카테고리를 추가할 상위 카테고리를 선택해주세요.')
        this.$emit('error', this.depth - 1, true)
        return false
      }
      this.$emit('error', this.depth - 1, false)

      let currentMaxSeq = -1
      if (this.list.length > 0) {
        const lastItem = this.list[this.list.length - 1]
        if (lastItem && lastItem.seq >= 0) {
          currentMaxSeq = lastItem.seq
        }
      }

      const item = {
        name: '',
        isEdit: false,
        isShow: true,
        isAdmin: false,
        deprecated: false,
        seq: currentMaxSeq + 1,
        children: [
        ]
      }

      if (this.depth === 1) {
        item.children.push(
          {
            name: '',
            isEdit: false,
            isShow: true,
            isAdmin: false,
            deprecated: false,
            children: [
              {
                name: '',
                isEdit: false,
                isShow: true,
                isAdmin: false,
                children: []
              }
            ]
          }
        )
      } else if (this.depth === 2) {
        item.children.push(
          {
            name: '',
            isEdit: false,
            isShow: true,
            isAdmin: false,
            deprecated: false,
            children: [
            ]
          }
        )
      }

      this.list.push(item)
      this.getListCount()
      this.onSelect(item, 'add')
    },
    onRemove () {
      const list = this.list

      this.getListCount()

      if (this.listCount === 1) {
        alert('마지막 카테고리는 삭제할 수 없습니다. \n 각 메뉴당 최소 1개의 카테고리가 필요합니다.')
        return false
      }

      let confirmResult

      if (this.depth !== 3) {
        confirmResult = confirm('카테고리 삭제 시, 하위 분류를 포함하여 작성된 게시글도 모두 삭제되며 복구할 수 없습니다. \n 메뉴를 삭제하시겠습니까?')
      } else {
        confirmResult = confirm('작성된 게시글도 모두 삭제되며 복구할 수 없습니다. \n 메뉴를 삭제하시겠습니까?')
      }

      let removeIdx

      if (confirmResult) {
        this.selectedItem.deprecated = false

        for (let i = 0, iLen = list.length; i < iLen; i++) {
          const listItem = list[i]

          if (this.selectedItem === listItem) {
            listItem.deprecated = true

            removeIdx = i

            listItem.children.forEach((item) => {
              item.deprecated = true
              if (item.children) {
                item.children.forEach((subItem) => {
                  subItem.deprecated = true
                })
              }
            })
          }
        }
      }

      if (removeIdx >= 0) {
        // this.$emit('remove', this.depth, this.list, removeIdx)
        this.onSelect(list[removeIdx - 1])
      }

      this.getListCount()
    },
    onChange () {
      if (this.selectedItem.depth === 2 && this.depth === 2) {
        if (this.parent.children.length === 1) {
          alert('카테고리가 1개 이하일 때는 비공개 설정이 불가능합니다.')
        } else {
          this.selectedItem.isShow = !this.selectedItem.isShow
        }
      }
      if (this.selectedItem.depth === 3 && this.depth === 3) {
        if (this.parent.children.length === 1) {
          alert('카테고리가 1개 이하일 때는 비공개 설정이 불가능합니다.')
        } else {
          this.selectedItem.isShow = !this.selectedItem.isShow
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #8d8888;
  color: #fff;
}
.category {
  float: left;
  width: 30%;
  margin: 20px;
}

.list {
  margin: 10px;
  height: 50vh;
  border: 1px solid rgba(0,0,0,0.125);

  &.error {
    border-color: #de1b1b;
  }
}

.button {
  margin-top: 35px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-align-justify:before{
  content: "\F039";
  box-sizing: border-box;
  text-decoration: inherit;
  vertical-align: inherit;
}
.handle {
  cursor: move;
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
.list-group {
  height: 100%;
  overflow: auto;
  min-height: 20px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item {
  cursor: pointer;
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,.125);

  &.select {
    background-color: #cecdcd;
  }

  .text {
    line-height: 28px;
  }
}
.list-group-item i {

}
</style>
