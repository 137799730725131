<template>
  <div class="default-layout"
       :class="{ sidebar: openSidebar }">
    <layout-sidebar class="layout-sidebar"/>
    <div class="toggle-sidebar"
          @click="toggleSidebar">
      <i class="xi-bars"></i>
    </div>
    <div class="layout-container">
      <transition name="fade">
        <layout-header v-show="!pageLoading"
                       class="layout-header"
                       :key="`header${$route.name}`"
                       :title="title"
                       :service="service">
          {{ title }}
        </layout-header>
      </transition>
      <transition name="fade">
        <div v-show="loading"
             class="layout-loading">
          <ui-loading />
        </div>
      </transition>
      <transition name="fade">
        <div v-show="!pageLoading"
             :key="`body${$route.name}`"
             class="layout-content">
          <router-view class="layout-content-wrap" />
        </div>
      </transition>
      <transition name="fade">
        <div v-show="!!toastMessage"
             class="layout-toast"
             v-html="realToastMessage">
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'

import LayoutHeader from '@/components/_layout/default/header'
import LayoutSidebar from '@/components/_layout/default/sidebar'
import UiLoading from '@/components/_ui/UiLoading'

export default {
  name: 'DefaultLayout',
  components: {
    LayoutHeader,
    LayoutSidebar,
    UiLoading
  },
  watch: {
    '$route' () {
      this.setHeaderProps()
      this.pageLoading = true
    }
  },
  created () {
    this.setHeaderProps()

    EventBus.$on('toastWarning', (message) => {
      this.toastMessage = message
      this.realToastMessage = message
      if (this.toastTimer) {
        clearTimeout(this.toastTimer)
      }
      this.toastTimer = setTimeout(() => {
        this.toastMessage = null
        setTimeout(() => {
          this.realToastMessage = null
        }, 300)
      }, 3000)
    })

    EventBus.$on('onLoading', (value) => {
      this.loading = value
      if (!value) {
        this.pageLoading = false
      }
    })
  },
  methods: {
    setHeaderProps () {
      this.title = this.$route.meta.title || ''
      if (this.$route.meta.beus) {
        this.service = 'Beusable'
      } else if (this.$route.meta.ba) {
        this.service = 'BA'
      } else if (this.$route.meta.ssbeus) {
        this.service = 'Samsung Beusable'
      } else {
        this.service = null
      }
    },
    toggleSidebar () {
      this.openSidebar = !this.openSidebar
    }
  },
  data () {
    return {
      title: '',
      service: null,
      openSidebar: true,
      pageLoading: false,
      loading: false,
      toastMessage: null,
      realToastMessage: null,
      toastTimer: null
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout {
  .layout-sidebar {
    z-index: 5;
    position: fixed;
    width: 210px;
    height: 100vh;
    overflow-y: auto;
  }

  .toggle-sidebar {
    z-index: 6;
    position: absolute;
    top: calc(100vh - 100px);
    left: 180px;
    color: $white;
    cursor: pointer;
    transition: left 0.3s ease;
  }

  .layout-container {
    z-index: 10;
    height: 100vh;
    position: relative;
    background-color: $white;
    margin-left: 200px;
    border-radius: 10px 0 0 10px;
    transition: margin-left 0.3s ease;

    .layout-content {
      max-height: calc(100vh - 72.64px);
      overflow: auto;
    }

    .layout-loading {
      z-index: 99999999;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .ui-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .layout-toast {
      z-index: 99999;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $gray;
      color: $white;
      margin-top: 13px;
      padding: 10px 13px;
      border-radius: 8px;
      box-shadow: 0 4px 7.5px 0 rgba(0, 0, 0, 0.16);

      &.fade-leave-active,
      &.fade-enter-active {
        transition: opacity 0.3s ease,
                    transform 0.3s ease;
        transform: translateX(-50%);
      }

      &.fade-enter,
      &.fade-leave-to {
        transform: translateX(-50%) translateY(-10px);
      }
    }
  }

  &:not(.sidebar) {
    .layout-header::v-deep {
      .header-title {
        .service {
          margin-left: 0;
        }
      }
    }

    .layout-sidebar::v-deep {
      h1 {
        .bi-eagle-logo {
          opacity: 0;
        }

        .bi-eagle {
          opacity: 1;
        }
      }

      .sidebar-nav {
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }

        &::-webkit-scrollbar-thumb {
          border: none;
        }
      }

      .router-wrap {
        span {
          color: transparent;
        }
      }
    }

    .toggle-sidebar {
      left: 23px;
    }

    .layout-container {
      margin-left: 54px;
    }
  }
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.3s ease;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
