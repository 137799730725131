<template>
  <div class="ui-editor"
       :class="{ readonly }">
    <ckeditor v-model="editorData" :disabled="readonly" :readonly="readonly"
              :config="editorConfig" />
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'

export default {
  name: 'UiEditor4',
  components: {
    ckeditor: CKEditor.component
  },
  data () {
    return {
      editorData: '',
      editorConfig: {
        allowedContent: true,
        height: 800,
        bodyId: (this.$route.meta.ba ? 'ba-notice' : 'be-notice-background'),
        extraPlugins: [
          'colorbutton',
          'basicstyles',
          'justify',
          'horizontalrule',
          'colordialog',
          'font'
        ]
      }
    }
  },
  props: {
    value: {
      default: '',
      required: true
    },
    readonly: {
      default: false
    }
  },
  watch: {
    value () {
      this.editorData = JSON.parse(JSON.stringify(this.value))
    },
    editorData (next, prev) {
      if (!this.readonly && next !== prev) {
        this.setValue(next)
      }
    }
  },
  created () {
    if (this.value) {
      this.editorData = JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    setValue (e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-editor {
  & :deep {
    .cke {
      border: none;
      margin: -15px -10px 0;
    }
  }

  &.readonly ::v-deep {
    .cke_top,
    .cke_bottom {
      display: none;
    }
    .cke {
      margin-top: -10px;
    }
  }
}
</style>
