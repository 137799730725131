<template>
  <ui-side-modal v-show="showing"
                 class="template-detail"
                 :width="modalWidth"
                 @close="closeModal">
    <ui-form v-if="dataOption && showing"
             :meta="dataOption"
             :data="model"
             :error="error"
             @set="setData"
             @error="setError"
             @input="input">
      <slot></slot>
      <ui-button :color="'gray'"
                 @click="save(false)">
        <i class="xi-close"></i> 취소
      </ui-button>
      <ui-button :color="'red'"
                 @click="save(true)">
        <i class="xi-pen"></i> 저장
      </ui-button>
    </ui-form>
  </ui-side-modal>
</template>

<script>
import isobject from 'isobject'
import { EventBus } from '@/utils/event-bus'
import { alertError, isEndWithConsonant } from '@/utils/tools'

import UiForm from '@/components/_ui/UiForm'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'

export default {
  name: 'TemplateInsert',
  components: {
    UiForm,
    UiButton,
    UiSideModal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    metaApi: {
      required: true
    },
    insertApi: {
      required: true
    },
    resultMsg: {
      default: false
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.loadMeta()
      } else {
        this.showing = false
        this.model = { ...this.initialModel }
      }
    }
  },
  created () {
    this.showing = this.show
    this.model = { ...this.initialModel }
  },
  computed: {
    modalWidth () {
      if (this.dataOption) {
        for (const option of Object.values(this.dataOption)) {
          if (option.type === 'editor' || option.type === 'editor4') {
            return 1200
          }
        }
      }
      return null
    }
  },
  methods: {
    loadMeta (callback = null) {
      this.error = {}
      this.showing = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.metaApi().then(response => {
          this.dataOption = response.result || null
          this.showing = true
          if (this.dataOption) {
            for (const [field, option] of Object.entries(this.dataOption)) {
              if (option.default) {
                this.$set(this.model, option.field || field, option.default)
              }
            }
          }
        }).catch(error => {
          alertError('메타 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    setData (data) {
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption)) {
          const key = option.field || field
          if (key.includes('.')) {
            const splitedKey = key.split('.')
            const key1 = splitedKey[0]
            const key2 = splitedKey[1]
            if (data[key1] && data[key1][key2] !== undefined) {
              this.$set(this.model, key, data[key1][key2])
            } else if (option.static) {
              this.$delete(this.model, key)
            }
          } else {
            if (data[key] !== undefined) {
              this.$set(this.model, key, data[key])
            } else if (option.static) {
              this.$delete(this.model, key)
            }
          }
        }
      }
    },
    setError (key, message) {
      this.$set(this.error, key, message)
    },
    checkRequired () {
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption)) {
          const key = option.field || field
          if (option.required && !this.model[key] && this.model[key] !== 0) {
            this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
          }
        }
      }
      for (const message of Object.values(this.error)) {
        if (message) {
          return false
        }
      }
      return true
    },
    save (save = false) {
      let confirmed = false
      if (this.checkRequired()) {
        confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
      } else {
        if (!save) {
          confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
        }
      }

      if (confirmed) {
        if (save) {
          EventBus.$emit('onLoading', true)
          this.insertApi(this.model, true).then(response => {
            this.$emit('close')
            this.$emit('saved', response.result)
          }).catch(error => {
            const message = error.response ? error.response.data.message : false
            if (isobject(message)) {
              for (const [key, value] of Object.entries(message)) {
                if (key !== 'idx') {
                  this.$set(this.error, key, value)
                }
              }
            }
            alertError('저장에 실패했습니다.', error.message || error)
          }).finally(() => {
            if (this.resultMsg) {
              alert(this.resultMsg)
            }
            this.$emit('reload')
            EventBus.$emit('onLoading', false)
          })
        } else {
          this.$emit('close')
        }
      }
    },
    input (field, value) {
      this.$delete(this.error, field)
      this.$set(this.model, field, value)
      this.$emit('input', field, value)
    },
    closeModal () {
      const confirmed = confirm('정말로 저장하지 않고 끝내시겠습니까?')
      if (confirmed) {
        this.$emit('close')
      }
    }
  },
  data () {
    return {
      showing: false,
      dataOption: null,
      model: {},
      initialModel: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      error: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
}
</style>
