<template>
  <div class="ui-input">
    <div class="ui-input-container"
         :class="[`type-${type}`, { disabled, error, focused, required }]">
      <label v-if="$slots.default"
             class="ui-input-label"
             :for="uuid">
        <slot></slot>
      </label>
      <div class="ui-input-wrap">
        <input :type="type"
               :id="uuid"
               :placeholder="placeholder"
               :disabled="disabled"
               :value="value"
               @keyup="onKeyup"
               @input="onInput"
               @focus="onFocus"
               @blur="onBlur" />
      </div>
      <i v-if="!disabled"
         class="xi-close"
         @click="onClear"></i>
      <span v-if="required"
            class="required">
        필수
      </span>
    </div>
    <div v-if="error"
         class="ui-input-error"
         v-html="error">
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'

export default {
  name: 'UiInput',
  props: {
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        return ['text', 'password', 'date', 'datetime-local', 'number', 'email', 'textarea', 'search'].includes(value)
      },
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      default: '',
      required: true
    },
    error: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    onKeyup (e) {
      this.$emit('keyup', e)
      if (e.key === 'Enter') {
        this.$emit('enter')
      }
    },
    onFocus () {
      this.focused = true
      this.$emit('focus')
    },
    onBlur () {
      this.focused = false
      this.$emit('blur')
    },
    onInput (e) {
      this.$emit('input', e.target.value)
    },
    onClear () {
      this.$emit('input', this.initialValue)
    }
  },
  data () {
    return {
      uuid: uuid.v4(),
      initialValue: JSON.parse(JSON.stringify(this.value)),
      focused: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-input {
  position: relative;
  padding-bottom: 20px;

  .ui-input-container {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 2px solid;
    border-bottom-color: $gray;
    transition: border-bottom-color 0.3s ease;

    .ui-input-label {
      flex-grow: 0;
      min-width: 70px;
      flex-basis: 70px;
      padding: 5px 0 5px 7px;
      color: $black;
      opacity: 0.5;
      transition: color 0.3s ease;
    }

    .ui-input-wrap {
      flex-grow: 1;
      padding: 5px 25px 5px 7px;

      input {
        border: none;
        background: transparent;
        color: $black;
        width: 100%;
        padding: 5px 20px 5px 7px;
        margin: -5px -20px -5px -7px;
      }
    }

    .required {
      position: absolute;
      top: 50%;
      right: 7px;
      display: block;
      width: 1px;
      height: 5px;
      border-radius: 20px;
      background-color: $orange;
      color: transparent;
      line-height: 17px;
      padding: 0 2px;
      overflow: hidden;
      font-size: 11px;
      transform: translateY(-50%);
      text-align: center;
      letter-spacing: -1px;
      transition: width 0.3s ease,
                  height 0.3s ease,
                  color 0.3s ease;
    }

    .xi-close {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $black;
      font-size: 12px;
      cursor: pointer;
      opacity: 0;
      transition: color 0.3s ease,
                  right 0.3s ease,
                  opacity 0.3s ease;
    }

    &.type-number {
      .ui-input-wrap {
        padding-right: 10px;

        input {
          padding-right: 0;
          margin-right: -13px;
        }
      }

      .xi-close {
        right: 33px;
      }
    }

    &.type-date,
    &.type-datetime-local {
      .ui-input-wrap {
        padding-right: 60px;

        input {
          padding-right: 0;
          margin-right: -13px;
        }
      }

      .xi-close {
        right: 12px;
      }
    }

    &.disabled {
      border-bottom-color: $white;
    }

    &.error {
      border-bottom-color: $red;
    }

    &.focused {
      border-bottom-color: $blue;

      .ui-input-label {
        color: $blue;
        opacity: 1;
      }

      .xi-close {
        opacity: 0.3;

        &:hover,
        &:active {
          opacity: 0.5;
        }
      }

      .required {
        width: 25px;
        height: 17px;
        color: $white;
      }
    }

    &.required {
      .xi-close {
        right: 20px;
      }

      &.focused {
        .xi-close {
          right: 40px;
        }
      }

      &.type-date,
      &.type-number {
        .xi-close {
          right: 30px;
        }

        &.focused {
          .xi-close {
            right: 50px;
          }
        }
      }
    }
  }

  .ui-input-error {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 12px;
    color: $red;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
