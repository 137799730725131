import { render, staticRenderFns } from "./ListButton.vue?vue&type=template&id=65e4b550&scoped=true"
import script from "./ListButton.vue?vue&type=script&lang=js"
export * from "./ListButton.vue?vue&type=script&lang=js"
import style0 from "./ListButton.vue?vue&type=style&index=0&id=65e4b550&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e4b550",
  null
  
)

export default component.exports