<template>
  <div class="default-layout-sidebar">
    <div class="sidebar-logo">
      <h1>
        <i>Beuillage</i>
        <i class="bi-eagle white"></i>
      </h1>
    </div>
    <nav class="sidebar-nav">
      <ul>
        <li>
          <router-link class="router-link"
                       :to="{ name: 'Dashboard' }">
            <span class="router-wrap">
              <span><i class="xi-dashboard"></i> Dashboard</span>
            </span>
          </router-link>
        </li>

        <li>
          <router-link class="router-link"
                       :to="{ name: 'User' }">
            <span class="router-wrap">
              <span><i class="xi-dashboard"></i> 회원 관리</span>
            </span>
          </router-link>
        </li>

        <li>
          <router-link class="router-link disabled"
                       :to="{ name: 'PostManager' }">
            <span class="router-wrap">
              <span><i class="xi-apps"></i> 글 관리</span>
            </span>
          </router-link>
          <ul>
            <router-link :tag="'li'" :to="{ name: 'PostList' }">
              <router-link class="router-link" :to="{ name: 'PostList' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 게시글 관리</span>
                </span>
              </router-link>
            </router-link>
            <router-link :tag="'li'" :to="{ name: 'PostReply' }">
              <router-link class="router-link" :to="{ name: 'PostReply' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 답글 관리</span>
                </span>
              </router-link>
            </router-link>
            <router-link :tag="'li'" :to="{ name: 'PostTop' }">
              <router-link class="router-link" :to="{ name: 'PostTop' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 인기 게시글 관리</span>
                </span>
              </router-link>
            </router-link>
            <router-link :tag="'li'" :to="{ name: 'PostRecommendation' }">
              <router-link class="router-link" :to="{ name: 'PostRecommendation' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 추천 컨텐츠 관리</span>
                </span>
              </router-link>
            </router-link>
            <router-link :tag="'li'" :to="{ name: 'TopKeyword' }">
              <router-link class="router-link" :to="{ name: 'TopKeyword' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 인기 키워드 태그 관리</span>
                </span>
              </router-link>
            </router-link>
            <router-link :tag="'li'" :to="{ name: 'ReportContent' }">
              <router-link class="router-link" :to="{ name: 'ReportContent' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 신고 컨텐츠 관리</span>
                </span>
              </router-link>
            </router-link>
          </ul>
        </li>

<!--        <li>-->
<!--          <router-link class="router-link disabled"-->
<!--                       :to="{ name: 'GuideManager' }">-->
<!--            <span class="router-wrap">-->
<!--              <span><i class="xi-apps"></i> 가이드 관리</span>-->
<!--            </span>-->
<!--          </router-link>-->
<!--          <ul>-->
<!--            <router-link :tag="'li'" :to="{ name: 'GuideCategory' }">-->
<!--              <router-link class="router-link" :to="{ name: 'GuideCategory' }">-->
<!--                <span class="router-wrap">-->
<!--                  <span><i class="xi-calendar-list"></i> 카테고리 관리</span>-->
<!--                </span>-->
<!--              </router-link>-->
<!--            </router-link>-->
<!--            <router-link :tag="'li'" :to="{ name: 'GuideHelpful' }">-->
<!--              <router-link class="router-link" :to="{ name: 'GuideHelpful' }">-->
<!--                <span class="router-wrap">-->
<!--                  <span><i class="xi-calendar-list"></i> Helpful Doc 관리</span>-->
<!--                </span>-->
<!--              </router-link>-->
<!--            </router-link>-->
<!--            <router-link :tag="'li'" :to="{ name: 'GuideList' }">-->
<!--              <router-link class="router-link" :to="{ name: 'GuideList' }">-->
<!--                <span class="router-wrap">-->
<!--                  <span><i class="xi-calendar-list"></i> 가이드 관리</span>-->
<!--                </span>-->
<!--              </router-link>-->
<!--            </router-link>-->
<!--            &lt;!&ndash; <router-link :tag="'li'" :to="{ name: 'GuideTroubleFaq' }">-->
<!--              <router-link class="router-link" :to="{ name: 'GuideTroubleFaq' }">-->
<!--                <span class="router-wrap">-->
<!--                  <span><i class="xi-calendar-list"></i> Trouble & FAQ</span>-->
<!--                </span>-->
<!--              </router-link>-->
<!--            </router-link> &ndash;&gt;-->
<!--          </ul>-->
<!--        </li>-->
        <li>
          <router-link class="router-link disabled"
                       :to="{ name: 'SolomonManager' }">
            <span class="router-wrap">
              <span><i class="xi-apps"></i> 솔로몬 관리</span>
            </span>
          </router-link>
          <ul>
           <router-link :tag="'li'" :to="{ name: 'SolomonCategory' }">
             <router-link class="router-link" :to="{ name: 'SolomonCategory' }">
               <span class="router-wrap">
                 <span><i class="xi-calendar-list"></i> 카테고리 관리</span>
               </span>
             </router-link>
           </router-link>
            <router-link :tag="'li'" :to="{ name: 'Solomon' }">
              <router-link class="router-link" :to="{ name: 'Solomon' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> FAQ 관리</span>
                </span>
              </router-link>
            </router-link>
            <router-link :tag="'li'" :to="{ name: 'SolomonReview' }">
              <router-link class="router-link" :to="{ name: 'SolomonReview' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 의견 모음집 관리</span>
                </span>
              </router-link>
            </router-link>
          </ul>
        </li>
      </ul>
    </nav>

    <div class="current-user">
      <button @click="onSignOut">
        <span class="user-id-title">현재 로그인 중</span>
        <span class="user-id">{{ userName }}</span>
        <i class="xi-log-out">
          <span class="a11y-invisible">로그아웃</span>
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { removeAuthToken } from '@/utils/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultSidebar',
  data () {
    return {
      isDev: false
    }
  },
  created () {
    if (this.userData.isDev) {
      this.isDev = true
    }
  },
  methods: {
    onSignOut () {
      removeAuthToken()
      this.$router.push({ name: 'SignIn' })
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    userName () {
      if (this.userData) {
        return this.userData.userId.split('@')[0]
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout-sidebar {
  display: flex;
  flex-direction: column;
  background-image: $black-gradient;
  background-size: 230px auto;
  background-attachment: fixed;

  .sidebar-logo {
    flex-grow: 0;

    h1 {
      position: relative;
      color: $white;
      font-weight: bold;
      font-size: 30px;
      padding: 21px 11px 10px;

      .bi-eagle-logo {
        transition: opacity 0.3s ease;
      }

      .bi-eagle {
        position: absolute;
        top: 29px;
        left: 10px;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }
  }

  .sidebar-nav {
    flex-grow: 1;
    overflow: auto;
    direction: rtl;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      border-right: none;
      box-shadow: inset 0 0 10px 10px $black;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $dark;
    }

    & > ul {
      overflow: hidden;
      padding: 15px 0;
      margin-left: -3px;
      direction: ltr;
    }

    .router-link {
      position: relative;
      color: $white;
      display: block;
      transition: color 0.3s ease;

      &.disabled {
        pointer-events: none;
        cursor: default;
        color: $light;

        i {
          color: $light;
        }
      }

      i {
        color: $white;
        margin-left: -6px;
        transition: margin-left 0.3s ease,
                    color 0.3s ease;
      }

      .router-wrap {
        display: block;
        padding: 10px 13px;

        span {
          position: relative;
          display: block;
          z-index: 3;
          transition: padding-left 0.3s ease,
                      color 0.3s ease;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -5px;
            width: 7px;
            height: 7px;
            color: $blue;
            background: $blue;
            border-radius: 5px;
            opacity: 0;
            transform: translateY(-50%);
            transition: opacity 0.3s ease;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: -10%;
          right: -100%;
          width: 100%;
          height: 120%;
          z-index: 2;
          background: $white;
          border-radius: 15px 0 0 15px;
          box-shadow: 0 23px 15px -12px rgba(0, 0, 0, 0.08);
          transition: right 0.3s ease;
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: -10px;
        width: 10px;
        height: 10px;
        border-right: 10px solid $white;
        border-radius: 0 0 20px 0;
        background-image: $black-gradient;
        background-size: 230px auto;
        background-attachment: fixed;
        transition: right 0.17s ease;
        transition-delay: 0.1s;
        z-index: 1;
      }

      &::before {
        top: calc(-10% - 10px);
        border-bottom: 10px solid $white;
        border-radius: 0 0 20px 0;
      }

      &::after {
        top: calc(110% - 10px);
        border-top: 10px solid $white;
        border-radius: 0 20px 0 0;
      }

      &.router-link-active {
        color: $black;

        .router-wrap {
          &::before {
            right: 0;
          }

          i {
            margin-left: 0;
            color: $blue;
          }
        }

        &::before,
        &::after {
          right: 0;
          transition-delay: 0s;
        }
      }

      &.router-link-exact-active {
        .router-wrap {
          span {
            padding-left: 7px;

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    ul {
      margin: 0 0 10px 10px;
      font-size: 15px;

      li {
        position: relative;

        .menu-services {
          position: absolute;
          z-index: 10;
          top: 4px;
          right: 14px;

          & > a {
            display: inline-block;
            margin: 4px;

            i {
              padding: 6px;
              border-radius: 12px;
              background-size: calc(100% - 12px);
              background-position: center;
              background-color: transparent;
              transition: background-color 0.2s ease;

              &.bi-beus {
                background-image: url('~@/assets/icon--beus-white.svg');
              }

              &.bi-ba {
                background-image: url('~@/assets/icon--ba-white.svg');
              }

              &.bi-samsung {
                background-image: url('~@/assets/icon--samsung-white.svg');
              }
            }

            &.router-link-active {
              i {
                &.bi-beus {
                  background-color: $red;
                  background-image: url('~@/assets/icon--beus-white.svg') !important;
                }

                &.bi-ba {
                  background-color: $red;
                  background-image: url('~@/assets/icon--ba-white.svg') !important;
                }

                &.bi-samsung {
                  background-color: $black;
                  background-image: url('~@/assets/icon--samsung-white.svg') !important;
                }
              }
            }

            &::before,
            &::after {
              display: none;
            }
          }
        }

        &.router-link-active > .menu-services {
          a {
            i {
              &.bi-beus {
                background-image: url('~@/assets/icon--beus.svg');
              }

              &.bi-ba {
                background-image: url('~@/assets/icon--ba.svg');
              }

              &.bi-samsung {
                background-image: url('~@/assets/icon--samsung.svg');
              }
            }
          }
        }
      }

      ul {
        position: relative;
        padding-left: 5px;

        &::before {
          content: "";
          position: absolute;
          top: 7px;
          left: 3px;
          width: 3px;
          height: calc(100% - 14px);
          background: $white;
          border-radius: 3px;
          opacity: 0.5;
        }

        .router-link {
          &.router-link-active {
            .router-wrap {
              &::before {
                right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .current-user {
    flex-grow: 0;

    button {
      width: calc(100% - 26px);
      background: rgba(255, 255, 255, 0.3);
      border-radius: 13px;
      margin: 0 18px 8px 8px;
      padding: 10px 10px 10px 15px;
      text-align: left;

      .user-id-title {
        display: block;
        font-size: 13px;
        color: $black;
        margin-bottom: 3px;
      }

      .user-id {
        font-weight: bold;
        color: $dim;
      }

      .xi-log-out {
        float: right;
        margin: 3px 0;
        color: $white;
        transition: color 0.3s ease;
      }

      &:hover,
      &.active {

        .xi-log-out {
          color: $red;
        }
      }
    }
  }
}
</style>
