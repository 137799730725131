import { request } from '@/utils/api-request'

export function getKeywordList (params) {
  const url = '/api/admin/keyword/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getKeywordInfo (params) {
  const url = '/api/admin/keyword/info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editKeywordInfo (params) {
  const url = '/api/admin/keyword/edit'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removeKeywordInfo (params) {
  const url = '/api/admin/keyword/remove'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
