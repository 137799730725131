import store from '@/store'
import { clearAllApiCache } from '@/utils/api-request'
import router from '@/router'
import { STORAGE_KEY } from '@/utils/constants'

const ACCESS_TOKEN = 'access_token'

export function setAuthToken (token) {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token))
    console.log(parseJwt(token.accessToken))
  } else {
    removeAuthToken()
  }
}

export function getAuthToken () {
  return localStorage.getItem(ACCESS_TOKEN) || null
}

export function removeAuthToken () {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(STORAGE_KEY.devTimestamp)
}

export function checkAuth () {
  return new Promise(resolve => {
    const authToken = getAuthToken()

    if (authToken) {
      const token = JSON.parse(authToken)

      if (!token.accessToken) {
        removeAuthToken()
        store.commit('setUserData', null)
        return resolve(null)
      } else {
        const identity = parseJwt(token.accessToken)
        const userData = {
          userId: identity.userId,
          id: identity.id
        }
        store.commit('setUserData', userData)
        return resolve(userData)
      }
    }
    return resolve(null)
  })
}

function b64DecodeUnicode (str) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(str), c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))
}

export function parseJwt (token) {
  return JSON.parse(
    b64DecodeUnicode(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    )
  )
}

export function signOut (redirect = true) {
  removeAuthToken()
  clearAllApiCache()
  if (redirect) {
    router.push({ name: 'SignIn' })
  }
}
