import axios from 'axios'
import { isPlainObject } from '@/utils/tools'
import { getAuthToken, removeAuthToken, signOut } from '@/utils/auth'

const baseUrl = process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000'

function isApiUrl (url) {
  if (url.charAt(0) === '/') {
    return true
  }
  return url.indexOf(baseUrl) === 0
}

function normalizeRequest (data) {
  if (isPlainObject(data)) {
    for (const key of Object.keys(data)) {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim()
      } else {
        normalizeRequest(data[key])
      }
    }
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      if (typeof data[i] === 'string') {
        data[i] = data[i].trim()
      } else {
        normalizeRequest(data[i])
      }
    }
  }
}

const httpClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
  }
})

httpClient.interceptors.request.use(function (config) {
  if (isApiUrl(config.url)) {
    if (config.data) {
      normalizeRequest(config.data)
    }

    if (config.method === 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params.t = new Date().getTime()
    }

    const authToken = getAuthToken()

    if (authToken) {
      const token = JSON.parse(authToken)

      if (!token.accessToken) {
        removeAuthToken()
      } else {
        config.headers = Object.assign(config.headers, {
          Authorization: `Bearer ${token.accessToken}`
        })
      }
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

httpClient.interceptors.response.use(function (response) {
  if (isApiUrl(response.config.url) && !response.data.success) {
    const message = response.data.message || 'RESPONSE_FAILED'
    const error = new Error(message)
    error.isFailed = true
    return Promise.reject(error)
  } else {
    return response
  }
}, function (error) {
  if (!error.response.config.skipError) {
    if (error.response.status === 401) {
      const redirect = confirm('로그인 되어 있지 않습니다.\n로그인 페이지로 이동하시겠습니까?')
      signOut(redirect)
    }
  }
  return Promise.reject(error)
})
export default httpClient
