const state = {
  beusPlanData: null,
  baPlanData: null
}

const getters = {
  beusPlanData: state => state.beusPlanData,
  baPlanData: state => state.baPlanData
}

const mutations = {
  setBeusPlanData (state, data) {
    state.beusPlanData = data
  },
  setBaPlanData (state, data) {
    state.baPlanData = data
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
