<template>
  <div class="dashboard">
    <div class="datetime">
      <div class="date-format">
        {{ date }}
      </div>
      <div class="time-format">
        {{ time }}
      </div>
    </div>
    <div class="card-cont">
      <div class="chart-card ">
        <div style="text-align: left;">
          당일 누적 컨텐츠 수
        </div>
        <div style="text-align: left; font-size: 5px; margin-top: 5px;">
          ※ 삭제된 콘텐츠는 집계에서 제외됩니다.
        </div>
        <h1 style="margin-top: 35px;">
          {{ contentCard }}건
        </h1>
      </div>
      <div class="chart-card ">
        <div class="card-subject">
          당일 누적 글 조회수
        </div>
        <div style="text-align: left;">
        </div>
        <h1>
          {{ viewCard }}건
        </h1>
      </div>
      <div class="chart-card ">
        <div style="text-align: left;">
        당일 누적 신고 수
        </div>
        <div style="text-align: left;">
        </div>
        <h1>
          {{ reportCard }}건
        </h1>
      </div>
    </div>
    <div class="chart">
      <div class="chart-subject">
         회원 가입 추이 (지난 30일)
      </div>
      <div class="chart-summary">
         가입자 수 (합계 : {{ sumOfpost }} )
      </div>
      <app-chart :chartType="'line'"
                 class="app-chart"
                 :key="chartKey"
                 :chartOptions="chartJoinUserData"
                 :height="250" />
    </div>
    <div class="chart">
      <div class="chart-subject">
         회원 탈퇴 추이 (지난 30일)
      </div>
      <div class="chart-summary">
         탈퇴자 수 (합계 : {{ sumOfreply }} )
      </div>
      <app-chart :chartType="'line'"
                 class="app-chart"
                 :key="chartKey"
                 :chartOptions="chartDeprecateUserData"
                 :height="250" />
    </div>
    <div style="display: flex;">
      <div class="chart" style="width: 50%; padding-right: 0px;">
        <div class="chart-subject">
          글,댓글 현황 (지난 30일)
        </div>
        <app-chart :chartType="'line'"
                  class="app-chart"
                  :key="chartKey"
                  :chartOptions="chartPostReplyData"
                  :height="525" />
      </div>

      <div class="chart" style="width: 50%; padding-right: 0px;">
        <div class="chart-subject" style="display: flex;">
          일자별 요약 (지난 30일)
          <div style="margin-left: 10px; margin-top: 8px; font-size: 7px;">
            ※ 삭제된 콘텐츠는 집계에서 제외됩니다.
          </div>
        </div>
        <ui-table-list v-if="columnData && listData"
                     :columnData="columnData"
                     :listData="listData"
                     :type="'report'"
                     :botColumn1="weekData"
                     :botColumn2="monthData"
                      />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { format } from 'date-fns'
import AppChart from '@/components/chart/index'
import { getChartData, getDailyReportData } from '../../../api/dashboard'
import UiTableList from '@/components/_ui/UiTableList'

export default {
  name: 'Dashboard',
  components: {
    AppChart,
    UiTableList
  },
  async created () {
    EventBus.$emit('onLoading', true)
    await this.loadData()
    EventBus.$emit('onLoading', false)
  },
  methods: {
    async loadData () {
      getChartData({type: 'joinUser'}).then(response => {
        if (response.result) {
          this.chartJoinUserData = response.result

          this.sumOfpost = this.chartJoinUserData.series[0].data.reduce((a, b) => (a + b))
          this.forceRerenderChart()
        }
      })
      getChartData({type: 'deprecateUser'}).then(response => {
        if (response.result) {
          this.chartDeprecateUserData = response.result
          this.sumOfreply = this.chartDeprecateUserData.series[0].data.reduce((a, b) => (a + b))
          this.forceRerenderChart()
        }
      })
      getDailyReportData().then(response => {
        if (response.result) {
          this.columnData = response.result.meta
          this.listData = response.result.list
          this.weekData = response.result.botColumn1
          this.monthData = response.result.botColumn2
          this.contentCard = this.listData[0].postCount + this.listData[0].replyCount
          this.reportCard = this.listData[0].reportCount
          this.viewCard = this.listData[0].viewCount
        }
      })
      await getChartData({type: 'post'}).then(response => {
        if (response.result) {
          this.postData = response.result
        }
      })
      await getChartData({type: 'reply'}).then(response => {
        if (response.result) {
          this.replyData = response.result
        }
      })
      if (this.postData && this.replyData) {
        this.chartPostReplyData.xAxis = {}
        this.chartPostReplyData.xAxis = this.postData.xAxis
        this.chartPostReplyData.tooltip = {}
        this.chartPostReplyData.tooltip = this.postData.tooltip
        this.chartPostReplyData.series = []
        this.chartPostReplyData.series.push(this.postData.series[0])
        this.chartPostReplyData.series.push(this.replyData.series[0])
        this.chartPostReplyData.legend = {data: []}
        this.chartPostReplyData.legend.data.push(this.postData.legend.data[0])
        this.chartPostReplyData.legend.data.push(this.replyData.legend.data[0])
        this.forceRerenderChart()
      }
      console.log('load')
    },
    forceRerenderChart () {
      this.chartKey += 1
    },
    formatAMPM () {
      let date = new Date()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let ampm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours || 12
      minutes = minutes < 10 ? '0' + minutes : minutes
      let strTime = hours + ':' + minutes + ' ' + ampm
      return strTime
    }
  },
  data () {
    return {
      chartJoinUserData: {},
      chartDeprecateUserData: {},
      postData: {},
      replyData: {},
      chartPostReplyData: {},
      chartKey: 0,
      columnData: null,
      listData: null,
      sumOfpost: 0,
      sumOfreply: 0,
      contentCard: 0,
      reportCard: 0,
      viewCard: 0,
      date: format(new Date(), 'yyyy-MM-dd'),
      time: this.formatAMPM()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 50px;
  padding-top: 10px;
  .datetime {
    display: flex;
    padding-bottom: 10px;
    .date-format {
      font-size: 25px;
      padding-right: 20px;
    }
    .time-format {
      padding-top: 10px;
      font-size: 15px;
    }
  }
  .info {
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 10px;
    & :deep {
      .ui-input, .ui-dropdown {
        width: 240px;
        display: inline-block;

        & > .ui-dropdown-container {
          height: 32px;
        }
      }

      .ui-button {
        padding-bottom: 20px;
      }
    }
    .seperator {
      padding: 0px 20px 20px 20px;
    }
  }
  .card-cont {
    display: flex;
    height: 200px;
    margin-bottom: 20px;
  }
  .card-subject {
    text-align: left;
  }
  .chart-card {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    border: 2px solid #bbbbbb;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin: 10px;

    & > h1 {
      margin: 45px 0;
      height: 100%;
      font-size: 30px;
    }
  }
  .chart {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    border: 1px solid #bbbbbb;
    text-align: center;
    padding-right: 10px;

    & > h1 {
      margin: 20px 0;
    }

    & > .ui-button {
      position: absolute;
      top: 13px;
      right: 13px;
    }
    .chart-subject {
      text-align: left;
      font-size: 17px;
      padding-left: 5px;
      padding-top: 10px;
      padding-bottom: 5px;
    }
    .chart-summary {
      text-align: left;
      font-size: 14px;
      padding-left: 5px;
    }
  }
  .temp {
    position: relative;
    width: 100%;
    height: calc(100vh - 73px);
    overflow: hidden;

    h1 {
      position: absolute;
      bottom: 3px;
      right: -0.5vw;
      line-height: 0.7;
      font-size: 5vw;
      font-weight: bold;
      color: $light;
      text-align: right;
    }
  }
}
</style>
