<template>
  <div>
    <div class="list-name">
      <list-drag :title="'대분류'" :depth="1" :list="depth1List" @select="onSelect" @error="onError" :error="depth1Error"></list-drag>
      <list-drag :title="'중분류'" :depth="2" :parent="selectedDepth1Item" :list="depth2List" @select="onSelect" @error="onError" :error="depth2Error"></list-drag>
      <list-drag :title="'소분류'" :depth="3" :parent="selectedDepth2Item" :list="depth3List" @select="onSelect" @error="onError" :error="depth3Error"></list-drag>
    </div>

    <div class="button">
      <ui-button @click="onSave">저장</ui-button>
    </div>

    <div class="desc">
      <ul>
        <li>상위 카테고리를 선택하고 ‘추가’를 누르면 하위 카테고리에 메뉴가 1개씩 생성됩니다.</li>
        <li>중분류는 2개 이상부터 활성화되며, 중분류가 1개인 경우엔 사용자들에게 노출되지 않습니다.</li>
        <li>드래그앤 드롭으로 메뉴 순서 변경이 가능합니다.</li>
        <li>상위 레벨의 메뉴가 ‘비공개’인 경우 하위 메뉴는 공개 여부와 관계없이 모두 비공개됩니다. </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import draggable from 'vuedraggable'
import { getCategoryList, updateCategoryList } from '@/api/category'
import UiButton from '@/components/_ui/UiButton'
import ListButton from './ListButton'
import ListDrag from './ListDrag'
import { mapGetters } from 'vuex'
import { DEFAULT_LANG } from '@/utils/constants'

export default {
  name: 'GuideCategory',
  components: {
    ListDrag,
    ListButton,
    UiButton,
    draggable
  },
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  watch: {
    locale (value) {
      this.loadList(value)
    }
  },
  data () {
    return {
      resultData: null,
      depth1List: [],
      depth2List: [],
      depth3List: [],
      selectedDepth1Item: null,
      selectedDepth2Item: null,
      depth1Error: null,
      depth2Error: null,
      depth3Error: null,
      dragging: false
    }
  },
  created () {
    EventBus.$emit('onLoading', false)
    this.loadList(DEFAULT_LANG)
  },
  methods: {
    loadList (lang = null) {
      EventBus.$emit('onLoading', true)
      getCategoryList({lang: lang}).then(response => {
        const data = response.data
        this.depth1List = data
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        EventBus.$emit('onLoading', false)
      })
    },
    onSelect (item, depth, type) {
      if (depth === 1) {
        this.selectedDepth1Item = item
        this.depth2List = item.children
        this.depth3List = []
        this.selectedDepth2Item = item.children[0]
        this.depth3List = item.children[0].children
      } else if (depth === 2) {
        this.selectedDepth2Item = item
        this.depth3List = item.children
      }
    },
    onSave () {
      this.depth1Error = false
      this.depth2Error = false
      this.depth3Error = false

      this.depth1List.forEach((item) => {
        if (item.name === '') {
          this.depth1Error = true
        }

        const depth2List = item.children.filter((subItem) => {
          return !subItem.deprecated
        })

        depth2List.forEach((subItem) => {
          let emptyNameCount = 0
          if (depth2List.length > 1) {
            if (subItem.name === '') {
              emptyNameCount++
            }
            if (emptyNameCount > 0) {
              this.depth2Error = true
            }
          }

          const depth3List = subItem.children.filter((subsubItem) => {
            return !subsubItem.deprecated
          })

          depth3List.forEach((subsubItem) => {
            if (subsubItem.name === '') {
              this.depth3Error = true
            }
          })
        })
      })

      if (!this.depth1Error && !this.depth2Error && !this.depth3Error) {
        EventBus.$emit('onLoading', true)
        if (confirm('저장하시겠습니까?')) {
          updateCategoryList({ code: this.depth1List, lang: this.locale }).then(response => {
            // location.reload()
            this.loadList(this.locale)
          }).catch(error => {
            console.error(error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
          })
        } else {
          EventBus.$emit('onLoading', false)
        }
      } else {
        alert('카테고리명을 입력하세요.')
        return false
      }
    },
    onError (depth, state) {
      console.log('error: ', depth, state)
      if (depth === 1) {
        this.depth1Error = state
      } else if (depth === 2) {
        this.depth2Error = state
      } else if (depth === 3) {
        this.depth3Error = state
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-name {
  display: inline-block;
  width: 100%;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #8d8888;
    color: #fff;
  }

  .list {
    margin: 10px;
    height: 50vh;
    border: 1px solid rgba(0,0,0,0.125);
  }

  .menu {
    .item {
      float: left;
      &:last-child {
        float: right;
      }
    }
  }
}

.button {
  padding-top: 50px;
  border-top: 2px solid #cbcbcb;
  text-align: center;
  margin: 50px 50px 0;
}

.desc {
  margin: 50px 50px 0;
  ul {
    li {
      list-style: disc;
    }
  }
}

</style>
