<template>
  <div class="ui-dropdown">
    <div class="ui-dropdown-container"
         :class="{ disabled, error, required }">
      <label v-if="$slots.default"
             class="ui-dropdown-label"
             :for="uuid">
        <slot></slot>
      </label>
      <div class="ui-dropdown-wrap">
        <span v-if="placeholder && !selected"
              class="placeholder">
            {{ placeholder }}
        </span>
        <span v-else-if="selected"
              class="value">
            {{ selected.name }}
        </span>
        <span v-else
              class="value">&nbsp;</span>
        <select :id="uuid"
                :value="value"
                :disabled="disabled"
                :class="{ long: !$slots.default }"
                @click="onClick"
                @change="onInput">
          <option v-for="option of options"
                  v-if="!model || !option.if || !isIf(model[option.if[0]], option.if[1], option.if[2])"
                  :key="`${option.name}-${option.value}`"
                  :value="option.value"
                  :disabled="option.disabled">
            {{ option.name }}
          </option>
        </select>
      </div>
      <i v-if="!disabled"
         class="xi-close"
         @click="onClear"></i>
      <span v-if="required"
            class="required">
        필수
      </span>
    </div>
    <div v-if="error"
         class="ui-dropdown-error"
         v-html="error">
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'
import { isIf } from '@/utils/tools'

export default {
  name: 'UiDropdown',
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      default: '',
      required: true
    },
    model: {
      default: null,
      required: false
    },
    options: {
      default: () => [],
      required: true
    },
    error: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    selected () {
      return this.options.find(option => option.value === this.value)
    }
  },
  methods: {
    isIf (a, b, c) {
      return isIf(a, b, c)
    },
    onClick () {
      this.$emit('focus')
    },
    onInput (e) {
      this.$emit('input', e.target.value)
    },
    onClear () {
      this.$emit('input', this.initialValue)
    }
  },
  data () {
    return {
      uuid: uuid.v4(),
      initialValue: JSON.parse(JSON.stringify(this.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-dropdown {
  position: relative;
  padding-bottom: 20px;

  .ui-dropdown-container {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 2px solid;
    border-bottom-color: $gray;
    transition: border-bottom-color 0.3s ease;

    .ui-dropdown-label {
      flex-grow: 0;
      min-width: 70px;
      flex-basis: 70px;
      padding: 5px 5px 5px 7px;
      color: $black;
      opacity: 0.5;
      transition: color 0.3s ease;
    }

    .ui-dropdown-wrap {
      flex-grow: 1;
      width: 1px;

      select {
        position: absolute;
        top: 0;
        left: 70px;
        width: calc(100% - 70px);
        height: 100%;
        appearance: none;
        opacity: 0;
        cursor: pointer;
        padding: 5px 30px 5px 7px;

        &.long {
          left: 0;
          width: 100%;
        }
      }

      .placeholder {
        width: calc(100% - 30px);
        color: $gray;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .value {
        width: calc(100% - 30px);
        color: $black;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px 30px 5px 7px;
      }
    }

    .required {
      position: absolute;
      top: 50%;
      right: 7px;
      display: block;
      width: 1px;
      height: 5px;
      border-radius: 20px;
      background-color: $orange;
      color: transparent;
      line-height: 17px;
      padding: 0 2px;
      overflow: hidden;
      font-size: 11px;
      transform: translateY(-50%);
      text-align: center;
      letter-spacing: -1px;
      transition: width 0.3s ease,
                  height 0.3s ease,
                  color 0.3s ease;
    }

    .xi-close {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $black;
      font-size: 12px;
      cursor: pointer;
      opacity: 0;
      transition: color 0.3s ease,
                  right 0.3s ease,
                  opacity 0.3s ease;
    }

    &.disabled {
      border-bottom-color: $white;
    }

    &.error {
      border-bottom-color: $red;
    }

    &:hover {
      .xi-close {
        opacity: 0.3;

        &:hover,
        &:active {
          opacity: 0.5;
        }
      }

      .required {
        width: 25px;
        height: 17px;
        color: $white;
      }
    }

    &.required {
      .xi-close {
        right: 20px;
      }

      &:hover {
        .xi-close {
          right: 40px;
        }
      }
    }
  }

  .ui-dropdown-error {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 12px;
    color: $red;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
