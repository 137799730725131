<template>
  <div class="menu">
    <div class="item">
      <ui-checkbox v-if="item" :disabled="!item" :value="!item.isShow" @input="onChange(item)" :reset="false">비공개</ui-checkbox>
      <ui-checkbox v-if="item && item.depth === 1" :disabled="!item" :value="item.isAdmin" @input="item.isAdmin = !item.isAdmin" :reset="false">관리자 공개</ui-checkbox>
    </div>
    <div class="item">
      <ui-button :color="'blue'" @click="$emit('add')">추가</ui-button>
      <ui-button :color="'red'" @click="$emit('remove')">삭제</ui-button>
    </div>
  </div>
</template>

<script>
import UiButton from '../../../_ui/UiButton'
import UiCheckbox from '../../../_ui/UiCheckbox'

export default {
  name: 'ListButton',
  components: {
    UiButton,
    UiCheckbox
  },
  props: [
    'item',
  ],
  methods: {
    onChange () {
      this.$emit('change')
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  margin: 0px 20px;
  display: flex;

  .item {
    &.admin {
      margin-left: 20px;
    }
    &:last-child {
      margin-left: auto;
    }
  }
}
</style>
