import { request } from '@/utils/api-request'

export function getHelpfulList (params) {
  const url = '/api/admin/helpful/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateHelpful (params) {
  const url = '/api/admin/helpful/update'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteHelpful (params) {
  const url = '/api/admin/helpful/delete'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
