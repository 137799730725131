import Vue from 'vue'
import Router from 'vue-router'

import { checkAuth } from '@/utils/auth'

import Blank from '@/components/Blank'
import SignIn from '@/components/SignIn'

import Dashboard from '@/components/views/dashboard/Dashboard'
import GuideCategory from '@/components/views/guide/category/List'
import GuideList from '../components/views/guide/guide/GuideList'
import FaqList from '../components/views/solomon/faq/FaqList'
import HelpfulList from '../components/views/guide/helpful/HelpfulList'
import UserList from '../components/views/user/UserList'
import PostList from '../components/views/post/PostList'
import PostReply from '../components/views/post/PostReply'
import ReportContent from '../components/views/post/ReportContent'
import TopPost from '../components/views/post/TopPost'
import TopKeyword from '../components/views/post/TopKeyword'
import SolomonCategory from '../components/views/solomon/category/List'
import ReviewList from '../components/views/solomon/review/ReviewList'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'SignIn',
      component: SignIn,
      meta: {
        layout: 'empty',
        guest: true
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        layout: 'default',
        index: true,
        member: true,
        title: 'Dashboard / Beusable Forum'
      }
    },
    {
      path: '/user',
      name: 'User',
      component: UserList,
      meta: {
        layout: 'default',
        index: true,
        member: true,
        title: 'User'
      }
    },
    {
      path: '/post_manager',
      name: 'PostManager',
      component: Blank,
      meta: {
        layout: 'default',
        member: true
      },
      children: [
        {
          path: '/post_manager/post',
          name: 'PostList',
          component: PostList,
          meta: {
            layout: 'default',
            member: true,
            title: '게시글 관리'
          }
        },
        {
          path: '/post_manager/reply',
          name: 'PostReply',
          component: PostReply,
          meta: {
            layout: 'default',
            member: true,
            title: '답글 관리'
          }
        },
        {
          path: '/post_manager/top_post',
          name: 'PostTop',
          component: TopPost,
          meta: {
            layout: 'default',
            member: true,
            title: '인기 게시글 관리'
          }
        },
        {
          path: '/post_manager/recommendation',
          name: 'PostRecommendation',
          component: Blank,
          meta: {
            layout: 'default',
            member: true,
            title: '추천 컨텐츠 관리'
          }
        },
        {
          path: '/post_manager/top_keyword',
          name: 'TopKeyword',
          component: TopKeyword,
          meta: {
            layout: 'default',
            member: true,
            title: '인기 키워드 태그 관리'
          }
        },
        {
          path: '/post_manager/report_content',
          name: 'ReportContent',
          component: ReportContent,
          meta: {
            layout: 'default',
            member: true,
            title: '신고 게시글 관리'
          }
        }
      ]
    },
    {
      path: '/guide_manager',
      name: 'GuideManager',
      component: Blank,
      meta: {
        layout: 'default',
        member: true,
        title: 'Guide'
      },
      children: [
        {
          path: '/guide_manager/category',
          name: 'GuideCategory',
          component: GuideCategory,
          meta: {
            layout: 'default',
            member: true,
            title: '카테고리 관리'
          }
        },
        {
          path: '/guide_manager/helpful',
          name: 'GuideHelpful',
          component: HelpfulList,
          meta: {
            layout: 'default',
            member: true,
            title: 'Helpful documents 관리'
          }
        },
        {
          path: '/guide_manager/guide',
          name: 'GuideList',
          component: GuideList,
          meta: {
            layout: 'default',
            member: true,
            title: '가이드 관리'
          }
        },
        // {
        //   path: '/guide_manager/faq',
        //   name: 'GuideTroubleFaq',
        //   component: FaqList,
        //   meta: {
        //     layout: 'default',
        //     member: true,
        //     title: 'Trouble&FAQ'
        //   }
        // }
      ]
    },
    {
      path: '/solomon_manager',
      name: 'SolomonManager',
      component: Blank,
      meta: {
        layout: 'default',
        member: true,
        title: 'Solomon'
      },
      children: [
        {
          path: '/solomon_manager/category',
          name: 'SolomonCategory',
          component: SolomonCategory,
          meta: {
            layout: 'default',
            member: true,
            title: '카테고리 관리'
          }
        },
        {
          path: '/solomon_manager/faq',
          name: 'Solomon',
          component: FaqList,
          meta: {
            layout: 'default',
            member: true,
            title: 'FAQ 관리'
          }
        },
        {
          path: '/solomon_manager/review',
          name: 'SolomonReview',
          component: ReviewList,
          meta: {
            layout: 'default',
            member: true,
            title: '의견 모음집 관리'
          }
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  checkAuth().then(userData => {
    if (to.meta.guest) {
      if (userData) {
        next({ name: 'Dashboard' })
      } else {
        next()
      }
    } else if (to.meta.member) {
      if (!userData) {
        next({ name: 'SignIn' })
      } else {
        next()
      }
    } else {
      next()
    }
  }).catch(() => {
    next({ name: 'SignIn' })
  })
})

export default router
