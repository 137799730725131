<template>
  <span v-if="computedValue !== null || value === null"
        class="ui-data-input"
        :class="[type1 ? `type1${type1}` : null,
                 type2 ? `type2${type2}` : null,
                 type3 ? `type3${type3}` : null]">
    <template v-if="type3 === 'range'">
        <ui-data-input class="range-start"
                       :type="type.replace('|range', '')"
                       :label="label"
                       :options="options"
                       :update="update"
                       :required="required"
                       :disabled="disabled"
                       :field="field"
                       :value="computedValue.start"
                       @enter="$emit('enter')"
                       @input="setRangeValue('start', $event)"
                       @update="$emit('update', $event)"
                       @focus="$emit('focus')" />
        <ui-data-input class="range-end"
                       :type="type.replace('|range', '')"
                       :label="'~'"
                       :options="options"
                       :update="update"
                       :error="error"
                       :required="required"
                       :disabled="disabled"
                       :field="field"
                       :value="computedValue.end"
                       @enter="$emit('enter')"
                       @input="setRangeValue('end', $event)"
                       @update="$emit('update', $event)"
                       @focus="$emit('focus')" />
    </template>
    <template v-else>
      <template v-if="type1 === 'selectcheck'">
        <ui-select-check :error="error"
                     :value="computedValue"
                     :options="computedOptions"
                     @input="setValue"
                     @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-select-check>
      </template>
      <template v-else-if="options">
        <ui-dropdown :required="required"
                     :options="computedOptions"
                     :error="error"
                     :value="computedValue"
                     :model="model"
                     @input="setValue"
                     @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-dropdown>
      </template>
      <template v-else-if="type1 === 'text'">
        <ui-input :type="'text'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  :minLength="minLength"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
          <template v-if="type2 === 'imageupload'">
            <input type="file"
                   class="a11y-invisible"
                   ref="file"
                   accept="image/*"
                   @change="imageRead">
            <ui-button @click="imageUpload">업로드</ui-button>
          </template>
          <template v-if="type2 === 's3upload'">
            <input type="file"
                   class="a11y-invisible"
                   ref="file"
                   accept="image/*"
                   @change="s3Read">
            <ui-button @click="imageUpload">업로드</ui-button>
          </template>

        </ui-input>
      </template>
      <template v-else-if="type1 === 'textarea'">
        <ui-textarea :type="'text'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  :minLength="minLength"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-textarea>
      </template>
      <template v-else-if="type1 === 'datetime'">
        <ui-input :type="type2 === 'date' ? 'date' : 'datetime-local'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-input>
      </template>
      <template v-else-if="type1 === 'number'">
        <ui-input :type="'number'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  :minLength="minLength"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-input>
      </template>
      <template v-else-if="type1 === 'boolean'">
        <ui-checkbox :error="error"
                     :value="computedValue"
                     @input="setValue"
                     @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-checkbox>
      </template>
      <template v-else-if="type1 === 'editor4'">
        <ui-editor-4 :value="computedValue"
                     @input="setValue" />
      </template>
      <template v-else-if="type1 === 'editor'">
        <ui-editor-5 :value="computedValue"
                     @input="setValue" />
      </template>
      <template v-else-if="type1 === 'html'">
        <ui-editor-4 :value="computedValue"
                     :readonly="disabled"
                     @input="setValue" />
      </template>
    </template>
  </span>
</template>

<script>
import isobject from 'isobject'
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import UiDataInput from '@/components/_ui/UiDataInput'
import UiInput from '@/components/_ui/UiInput'
import UiButton from '@/components/_ui/UiButton'
import UiDropdown from '@/components/_ui/UiDropdown'
import UiCheckbox from '@/components/_ui/UiCheckbox'

import UiSelectCheck from '@/components/_ui/UiSelectCheck'

import UiEditor4 from '@/components/_ui/UiEditor4'
import UiEditor5 from '@/components/_ui/UiEditor5'
import UiTextarea from '@/components/_ui/UiTextarea'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'UiDataInput',
  components: {
    UiTextarea,
    UiDataInput,
    UiInput,
    UiButton,
    UiDropdown,
    UiCheckbox,
    UiSelectCheck,
    UiEditor4,
    UiEditor5
  },
  props: {
    type: {
      type: String,
      default: 'text',
      required: false
    },
    field: {
      type: String,
      required: false
    },
    model: {
      required: false
    },
    value: {
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    update: {
      type: String,
      required: false
    },
    options: {
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    list: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    error: {
      type: String,
      default: '',
      required: false
    },
    minLength: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapGetters([
      'beusPlanData',
      'baPlanData'
    ]),

    splitedType () {
      return this.type.split('|')
    },
    type1 () {
      return this.splitedType[0] || null
    },
    type2 () {
      return this.splitedType[1] || null
    },
    type3 () {
      return this.splitedType[2] || null
    },
    type4 () {
      return this.splitedType[3] || null
    },
    computedOptions () {
      if (this.options === 'beusPlanList') {
        return Object.values(this.beusPlanData).filter(plan => {
          return (this.type3 !== 'noTrial' || plan.planName !== 'Trial')
        }).map(plan => {
          let name = plan.planName
          if (plan.bFree) {
            name += ' [Free]'
          }
          if (plan.pvLimit) {
            name += ` (${plan.pvLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} PV)`
          }
          return {
            name: name,
            value: plan.planNumber
          }
        })
      } else if (this.options === 'baPlanList') {
        return Object.values(this.baPlanData).filter(plan => {
          return (this.type3 !== 'noTrial' || plan.planName !== 'Trial')
        }).map(plan => {
          let name = plan.planName
          if (plan.bFree) {
            name += ' [Free]'
          }
          if (plan.pvLimit) {
            name += ` (${plan.pvLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} PV)`
          }
          return {
            name: name,
            value: plan.planNumber
          }
        })
      } else if (this.options === 'popupTemplateList') {
        return this.ayncList
      } else {
        return this.options
      }
    }
  },
  watch: {
    value () {
      this.setComputedValue()
    },
    computedValue (value) {
      if ((this.type2 === 'user' || this.type2 === 'notexistuser') && this.searched !== this.computedValue) {
        this.$emit('error', this.field, '확인 버튼을 눌러 확인하세요.')
      }

      if (this.type1 === 'number' && this.type3 === 'limit') {
        if (value > Number(this.type4)) {
          this.$emit('error', this.field, this.type4 + '까지 입력이 가능합니다.')
        }
      }

      if (this.minLength > -1) {
        const valueLength = value.length
        if (valueLength < this.minLength) {
          this.$emit('error', this.field, this.minLength + '이상 입력해주세요.')
        }
      }
    }
  },
  methods: {
    computeValue (result) {
      if (result === 0 || result === false || result) {
        if (this.type1 === 'text') {
          result = String(result)
          if (this.type3 === 'urlEncode') {
            result = decodeURI(result)
          } else if (this.type3 === 'json') {
            result = JSON.stringify(result)
          }
        } else if (this.type1 === 'number') {
          result = Number(result)
        } else if (this.type1 === 'datetime') {
          const date = new Date(result)
          if (this.type2 === 'date') {
            result = format(date, 'yyyy-MM-dd')
          } else {
            result = format(date, 'yyyy-MM-dd HH:mm:ss')
          }
        }
      }
      return result
    },

    setComputedValue () {
      if (this.type3 !== 'range') {
        this.computedValue = this.computeValue(this.value)
      } else {
        this.computedValue = {}
        if (this.value) {
          if (this.value.start || this.value.start === 0) {
            this.computedValue.start = this.computeValue(this.value.start)
          }
          if (this.value.end || this.value.end === 0) {
            this.computedValue.end = this.computeValue(this.value.end)
          }
        }
      }
    },

    filterValue (value) {
      if (value === 0 || value === false || value) {
        if (this.type1 === 'text') {
          value = String(value)
          if (this.type3 === 'urlEncode') {
            value = encodeURI(value)
          } else if (this.type3 === 'json') {
            value = JSON.parse(this.value)
          }
        } else if (this.type1 === 'number') {
          value = Number(value)
        } else if (this.type1 === 'boolean') {
          if (String(value).toLowerCase() === 'false') {
            value = false
          }
          value = Boolean(value)
        } else if (this.type1 === 'datetime') {
          if (this.type2 !== 'date') {

          }
        }
      }
      return value
    },

    setValue (value) {
      this.computedValue = this.filterValue(value)
      this.$emit('input', this.computedValue)
      if (this.update) {
        let otherValue = this.computedValue
        if (this.computedOptions) {
          for (const item of this.computedOptions) {
            if (item.content && value === item.value) {
              otherValue = item.content
              break
            }
          }
        }
        this.$emit('update', this.update, otherValue)
      }
    },

    setRangeValue (key, value) {
      if (!isobject(this.computedValue)) {
        this.computedValue = {}
      }
      this.$set(this.computedValue, key, this.filterValue(value))
      this.$emit('input', this.computedValue)
    },
    imageUpload () {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    imageRead (event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const base64 = btoa(e.target.result)
          this.setValue(`data:${file.type};base64,${base64}`)
        }
        reader.readAsBinaryString(file)
      }
    },
    s3Read (event) {
      const file = event.target.files[0]
      if (file) {
        EventBus.$emit('onLoading', true)
        const reader = new FileReader()
        reader.onload = (e) => {
          const base64 = btoa(e.target.result)
          this.$emit('update', 'fileName', file.name)
          this.$emit('update', 'imagePath', file.name)
          this.$emit('update', 'file', `${base64}`)
          this.$emit('update', 'type', file.type)
        }
        reader.readAsBinaryString(file)
        EventBus.$emit('onLoading', false)
      }
    }
  },
  created () {
    this.setComputedValue()
    this.$emit('input', this.computedValue)
    if ((this.type2 === 'user' || this.type2 === 'notexistuser') && !this.value) {
      this.searched = false
      this.$emit('error', this.field, '확인 버튼을 눌러 확인하세요.')
    }
  },
  data () {
    return {
      computedValue: null,
      searched: true,
      ayncList: []
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-data-input {
  &.type3range {
    .range-start::v-deep {
      .ui-input {
        padding-bottom: 0;
        .ui-input-container {
          border-bottom: none;
        }
      }
    }

    .range-end::v-deep {
      .ui-input-label {
        text-align: right;
      }
    }

    .range-end {

    }
  }
}
</style>
