<template>
  <div class="user-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail"
                          @goDetail="goDetail">

<!--      <ui-button :color="'purple'"-->
<!--                 @click="toggleExcel(true)">-->
<!--        <i class="xi-view-list"></i> 엑셀 다운로드-->
<!--      </ui-button>-->
    </template-search-list>
    <template-detail :infoApi="infoApi"
                      :apiParam="apiParam"
                      :menu="'faq'"
                      @button="button"
                      @reload="reloadList">
    </template-detail>

  </div>
</template>

<script>

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { EventBus } from '@/utils/event-bus'
import { STORAGE_ITEM } from '@/utils/constants'
import { getGuideList } from '../../../../api/guide'
import { getSolomonInfo } from '@/api/solomon'
import { NODE_ENV } from '../../../../utils/constants'

export default {
  name: 'FaqList',
  components: {
    // ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && (searchData.subject || searchData.tagString)) {
        this.initialSearchFilter = {}
        if (searchData.subject) {
          this.initialSearchFilter.subject = searchData.subject
        }
        if (searchData.tagString) {
          this.initialSearchFilter.tagString = searchData.tagString
        }
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    goDetail (data) {
      const lang = data.lang
      let domain = `https://staging-forum.beusable.net/${lang}/solomon`
      if (NODE_ENV === 'production') {
        domain = `https://forum.beusable.net/${lang}/solomon`
      }
      window.open(domain + '/post/' + data.id)
    },
    showDetail (data) {
      this.apiParam = { id: data.id }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleChange (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.change = !this.show.change
      } else {
        this.show.change = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_user'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('user', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      const nameMap = {
        userBeusPlan: 'PlanBeus',
        userBaPlan: 'PlanBa',
        userBeusApply: 'ServiceBeusApply',
        userBaDomain: 'ServiceBaDomain',
        userBeusCredit: 'CreditBeus',
        userBaCredit: 'CreditBa',
        commentForum: 'SolomonReview'
      }

      const param = {}

      if (value === 'commentForum') {
        param.subject = data
      }

      localStorage.setItem(STORAGE_ITEM.searchData, JSON.stringify(param))
      const route = this.$router.resolve({ name: nameMap[value], params: this.apiParam })
      window.open(route.href, '_blank')
    }
  },
  data () {
    return {
      reload: 0,

      infoApi: getSolomonInfo,
      apiParam: null,

      listApi: getGuideList,
      buttonOption: [
        {
          action: 'goDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        },
        {
          action: 'showDetail',
          columnText: '상세 보기',
          buttonText: '<i class="xi-arrow-right"></i>'
        },
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        change: false
      },
      excelDownload: false,
      initialSearchFilter: {
        key: 'FAQ'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list-wrap {
}
</style>
