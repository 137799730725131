<template>
  <div class="user-list-wrap">
    <div class="desc">
      <p>
        메인 페이지에 최소 1개부터 최대6개까지 가이드를 노출할 수 있습니다.
        <br><br>
        업로드된 순서대로 가이드가 노출됩니다.
      </p>
    </div>

    <template v-for="item  in list" >
      <div class="upload" :key="item.seq">
        <input type="file"
               class="a11y-invisible"
               :id="'file' + item.seq"
               accept="image/*,.pdf,application/vnd.ms-powerpoint"
               @change="imageRead"
        />
        <ui-button @click="imageUpload(item.seq)" class="help-btn">업로드</ui-button>
        <input type="text" placeholder="선택한 파일 없음" v-model="item.fileName" readonly>
        <i v-if="item.fileName"
           class="xi-close"
           @click="onDelete(item)"></i>
      </div>
    </template>

    <div class="button">
      <ui-button @click="onSave">저장</ui-button>
    </div>
  </div>
</template>

<script>

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'

import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { deleteHelpful, getHelpfulList, updateHelpful } from '../../../../api/helpful'

export default {
  name: 'HelpfulList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    UiButton
  },
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  watch: {
    locale (value) {
      this.list = []
      this.loadData(value)
    }
  },
  created () {
    EventBus.$emit('onLoading', false)
    this.loadData(this.locale)
  },
  methods: {
    imageUpload (seq) {
      console.log(seq)
      const name = 'file' + seq
      const dom = document.querySelector('#' + name)
      dom.value = ''
      dom.click()
      this.currentSeq = seq
      console.log(this.list[this.currentSeq])
    },
    imageRead (event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const base64 = btoa(e.target.result)
          const item = this.list[this.currentSeq]
          if (item.fileName !== '') {
            item.oldFileName = item.fileName
          }
          item.fileName = file.name
          item.value = `data:${file.type};base64,${base64}`
          item.contentType = file.type
          this.list[this.currentSeq] = item
          // this.$emit('update', 'fileName', file.name)
          // this.$emit('update', 'imagePath', file.name)
          // this.$emit('update', 'file', `${base64}`)
          // this.$emit('update', 'type', file.type)
        }
        reader.readAsBinaryString(file)
      }
    },
    loadData (lang) {
      const apiParam = {lang: lang}
      getHelpfulList(apiParam).then(response => {
        const list = response.result.list

        for (let i = 0, iLen = 6; i < iLen; i++) {
          let currentItem = false
          list.forEach(item => {
            if (item.seq === i) {
              currentItem = item
            }
          })

          if (currentItem) {
            this.list.push(currentItem)
          } else {
            this.list.push({ fileName: '', seq: i })
          }
        }
      }).catch(e => {

      })
      EventBus.$emit('onLoading', false)
    },
    onSave () {
      const list = this.list
      let fileNameCount = 0
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        if (item.fileName) {
          fileNameCount++
        }
      }

      if (!fileNameCount) {
        alert('파일을 1개 이상 업로드해주세요.')
        return false
      } else {
        EventBus.$emit('onLoading', true)

        updateHelpful({ list: this.list, lang: this.locale }).then(response => {
          EventBus.$emit('onLoading', false)
          this.list = []
          this.loadData(this.locale)
        })
      }
    },
    onDelete (item) {
      if (confirm(item.fileName + '을 삭제하시겠습니까?')) {
        if (item.path) {
          deleteHelpful({ data: item }).then(response => {
            EventBus.$emit('loading', false)

            this.list.forEach(listItem => {
              if (listItem.fileName === item.fileName && listItem.seq === item.seq) {
                listItem.fileName = ''
                delete listItem.id
                delete listItem.value
              }
            })
            this.list = []
            this.loadData(this.locale)
          })
        } else {
          this.list = []
          this.loadData(this.locale)
        }
      }
    }
  },
  data () {
    return {
      list: [],
      currentSeq: null
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list-wrap {

  margin-left: 50px;

  .desc {
    padding: 20px 0px;
  }

  .upload {
    border: 1px solid #000000;
    width: 500px;
    padding: 10px;
    margin: 10px 5px;
  }

  input {
    border: none;
    background: transparent;
    color: #333;
    width: 300px;
    padding: 5px 20px 5px 7px;
  }

  .help-btn {
    margin-left: 0;
  }

  table {

    tr {
      td {
        border: 1px solid #000000;
      }
    }
  }

  .xi-close {
    cursor: pointer;
    float: right;
    padding-top: 5px;
  }
}
</style>
