<template>
  <div v-if="type1 === 'list'"
       class="ui-data list">
    <ui-table-list v-if="option && value && value.length"
                   :inside="true"
                   :columnData="option"
                   :columnFixed="columnFixed"
                   :listData="value"
                   :sortBy="sortBy"
                   @set="setTableData"
                   @changeSort="changeTableSort"
                   @button="button"
                   @buttonAction="buttonAction" />
  </div>
  <div v-else-if="type1 === 'tab'">
    <ui-tab v-if="option && value"
            :tab="option"
            :data="value"
            @button="button" />
  </div>
  <div v-else-if="type1 === 'editor4'">
    <ui-editor-4 :value="value"
                 :readonly="true" />
  </div>
  <div v-else-if="type1 === 'editor'">
    <ui-editor-5 :value="value"
                 :readonly="true"  />
  </div>
  <div v-else-if="type1 === 'multiline'"
       class="ui-multiline">
    <div v-for="[field, line] in Object.entries(option)"
         class="ui-multiline-line"
         :key="`data-${field}`">
      <ui-data :type="line.type"
               :value="value[line.field]"
               :option="line.option"
               :listData="line.options"
               :sortBy="line.sortBy"
               @button="button" />
    </div>
  </div>
  <div v-else-if="type1 === 'button'">
    <div class="text-button" v-if="option.value === 'userForum' || option.value === 'postForum' || option.value === 'postReplyForum'">
      <div class="button-with-text">
        <span class="content-text">
        {{ option.textField }}
        </span>
      </div>
      <ui-button :color="option.color" @click="$emit('button', option.value, option.searchKey ? option.searchKey : option.textField)">
        {{option.label}}
      </ui-button>
    </div>
    <div v-else-if="option.value === 'commentForum'">
      <ui-button :color="option.color" @click="$emit('button', option.value, option.textField)">
        {{option.label}}
      </ui-button>
    </div>
    <div v-else>
      <ui-button :color="option.color" :disabled="option.value === 'deleteUser' && model.deprecated === 1" @click="$emit('button', option.value)">
        {{option.label}}
      </ui-button>

    </div>
  </div>
  <span v-else
        class="ui-data"
        :class="[type1 ? `type1${type1}` : null,
                 type2 ? `type2${type2}` : null,
                 type3 ? `type3${type3}` : null,
                 align ? align: null
                 ]"
        v-html="computedValue"
        :title="[type1 != 'html' && type2 !='imageupload' ? value: '']"
        ref="dataUi"
        @click="copy"></span>
</template>

<script>
import { format } from 'date-fns'
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz'

import { mapGetters } from 'vuex'
import { toastWarning } from '@/utils/tools'

import UiEditor4 from '@/components/_ui/UiEditor4'
import UiEditor5 from '@/components/_ui/UiEditor5'
import UiButton from '@/components/_ui/UiButton'
import { FORUM_HOME_URL } from '@/utils/constants'

const entityMap = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;', '`': '&#x60;', '=': '&#x3D;' }

export default {
  name: 'UiData',
  components: {
    UiButton,
    UiEditor4,
    UiEditor5,
    UiTableList: () => import('@/components/_ui/UiTableList'),
    UiTab: () => import('@/components/_ui/UiTab'),
    UiData: () => import('@/components/_ui/UiData')
  },
  props: {
    type: {
      type: String,
      default: 'text',
      required: false
    },
    value: {
      required: false
    },
    option: {
      required: false
    },
    listData: {
      required: false
    },
    sortBy: {
      required: false
    },
    align: {
      required: false
    },
    model: {
      required: false
    },
    colName: {
      required: false
    }
  },
  data () {
    return {
      columnFixed: -1
    }
  },
  created () {
    if (this.type === 'list' && this.option) {
      Object.entries(this.option).forEach(([key, value], index) => {
        if (value.columnFixed) {
          this.columnFixed = index
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'beusPlanData',
      'baPlanData'
    ]),

    splitedType () {
      return this.type.split('|')
    },
    type1 () {
      return this.splitedType[0] || null
    },
    type2 () {
      return this.splitedType[1] || null
    },
    type3 () {
      return this.splitedType[2] || null
    },
    computedValue () {
      let result = this.value
      if (result === 0 || result === false || result) {
        if (this.type1 === 'text') {
          if (this.type2 === 'reportType') {
            if (result === 'post') {
              result = '게시글'
            } else {
              result = '답글'
            }
          }
          if (this.type3 === 'urlEncode') {
            result = decodeURI(result)
          } else if (this.type3 === 'json') {
            result = JSON.stringify(result, null, 4)
          } else {
            result = String(result).replace(/[&<>"'`=]/g, (s) => entityMap[s])
          }

          if (this.type2 === 'email') {
            result = `${result}`
          } else if (this.type2 === 'password') {
            result = `<i class="xi-key"></i> ${result}`
          } else if (this.type2 === 'tel') {
            const tel = result.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
            result = `${tel}`
          } else if (this.type2 === 'url') {
            result = `${result}`
          } else if (this.type2 === 'card') {

          } else if (this.type2 === 'service') {
            if (result === 'beusable') {
              result = '<i class="bi-beus"></i> Beusable'
            } else if (result === 'ba') {
              result = '<i class="bi-ba"></i> BA'
            } else if (result === 'admin') {
              result = '<i class="bi-eagle"></i> ADMIN'
            }
          } else if (this.type2 === 'state') {
            if (this.type3 === 'show') {
              if (result === 'true') {
                result = '<span class="state blue">노출</span>'
              } else {
                result = '<span class="state red">제외</span>'
              }
            } else {
              if (result === 'unpaid') {
                result = '<span class="state red">실패</span>'
              } else if (result === 'complete') {
                result = '<span class="state blue">완료</span>'
              } else if (result === 'waiting') {
                result = '<span class="state dim">대기</span>'
              } else if (result === 'future') {
                result = ''
              } else if (result === 'exact') {
                result = '<span class="match">Exact</span>'
              } else if (result === 'simple') {
                result = '<span class="match">Simple</span>'
              } else if (result === 'start') {
                result = '<span class="match">Starts</span>'
              } else if (result === 'end') {
                result = '<span class="match">Ends</span>'
              } else if (result === 'contains') {
                result = '<span class="match">Contains</span>'
              } else if (result === 'uninstalled') {
                result = '<span class="state purple">Uninstalled</span>'
              } else if (result === 'error') {
                result = '<span class="state red">ERROR</span>'
              } else if (result === 'stop') {
                result = '<span class="state dim">STOP</span>'
              } else if (result === 'run') {
                result = '<span class="state blue">RUN</span>'
              } else if (result === 'scheduledRun') {
                result = '<span class="state dim">분석예약</span>'
              } else if (result === 'analyzing') {
                result = '<span class="state blue">분석 중</span>'
              } else if (result === 'tempStop') {
                result = '<span class="state dim">TempStop</span>'
              } else if (result === 'endByAnalysisDate') {
                result = '<span class="state dim">End (분석종료일)</span>'
              } else if (result === 'endByPvLimit') {
                result = '<span class="state dim">End (Pv 제한)</span>'
              } else if (result === 'open') {
                result = '<span class="state blue">공개</span>'
              } else if (result === 'private') {
                result = '<span class="state red">비공개</span>'
              } else if (result === 'close') {
                result = '<span class="state dim">종료</span>'
              } else if (result === 'hide') {
                result = '<span class="state yellow">비공개</span>'
              } else if (result === 'reported') {
                result = '<span class="state red">숨김</span>'
              } else if (result === 'deprecated') {
                result = '<span class="state yellow">탈퇴</span>'
              } else if (result === 'banned') {
                result = '<span class="state red">접근 제한</span>'
              } else if (result === 'normal') {
                result = '<span class="state blue">일반</span>'
              } else if (result === 'postReply') {
                result = '<span class="state blue">댓글</span>'
              } else if (result === 'responseReply') {
                result = '<span class="state yellow">답글</span>'
              } else if (result === 'notReported') {
                result = '<span class="state blue">숨김 해제</span>'
              } else if (result === 'adminReport') {
                result = '<span class="state red">수동 숨김</span>'
              } else if (result === 'userReport') {
                result = '<span class="state yellow">자동 숨김</span>'
              } else if (result === 'N/A') {
                result = '<span class="state gray">N/A</span>'
              }
            }
          } else if (this.type2 === 'type') {
            if (result === 'general') {
              result = '<span class="state green">일반</span>'
            } else if (result === 'lecture/fest') {
              result = '<span class="state purple">교육/행사</span>'
            } else if (result === 'event') {
              result = '<span class="state blue">이벤트</span>'
            } else if (result === 'fault') {
              result = '<span class="state red">장애공지</span>'
            } else if (result === 'etc') {
              result = '<span class="state dim">기타</span>'
            }
          } else if (this.type2 === 'location') {
            if (result === 'main') {
              result = '<i class="xi-home"></i> 홈'
            } else if (result === 'dashboard') {
              result = '<i class="xi-dashboard-o"></i> 대시보드'
            } else if (result === 'funnel') {
              result = '<i class="xi-filter"></i> 퍼널'
            }
          } else if (this.type2 === 'method') {
            if (result === 'card') {
              result = '<span class="method card"><i class="xi-credit-card"></i> 카드</span>'
            } else if (result === 'wire transfer') {
              result = '<span class="method wire"><i class="xi-money"></i> 계좌</span>'
            } else if (result === 'coupon') {
              result = '<span class="method coupon"><i class="xi-coupon"></i> 쿠폰</span>'
            } else if (result === '4grit') {
              result = '<span class="method admin"><i class="xi-gift-o"></i> 4GRIT</span>'
            } else if (result === 'general') {
              result = '일반'
            } else if (result === 'admin') {
              result = '<span class="method admin"><i class="xi-briefcase"></i> 어드민</span>'
            }
          } else if (this.type2 === 'lang') {
            if (result === 'ko') {
              result = '<span class="static"><i class="bi-lang-ko"></i> KO</span>'
            } else if (result === 'en') {
              result = '<span class="static"><i class="bi-lang-en"></i> EN</span>'
            } else if (result === 'ja') {
              result = '<span class="static"><i class="bi-lang-ja"></i> JA</span>'
            }
          } else if (this.type2 === 'timezone') {
            let tz = getTimezoneOffset(result) / 60 / 60 / 1000
            if (tz >= 0) {
              tz = '+' + tz
            }
            result += ` <span class="tip timezone">UTC${tz}</span>`
          } else if (this.type2 === 'longtext') {
            result = result.replace(/\n/g, '<br>')
          } else if (this.type2 === 'static') {
            result = `<span class="static">${result}</span>`
          } else if (this.type2 === 'user') {

          } else if (this.type2 === 'code') {
            result = `<span class="code"><pre>${result}</pre></span>`
          } else if (this.listData) {
            for (const item of this.listData) {
              if (item.value === result) {
                result = item.name
              }
            }
          }
        } else if (this.type1 === 'datetime') {
          if (this.type2 === 'date') {
            const formatStr = 'yyyy-MM-dd'
            result = format(utcToZonedTime(result, 'Asia/Seoul'), formatStr) +
              ' <span class="tip timezone">UTC+9</span>'
          } else if (this.type2.indexOf('UTC+') === 0) {
            let tz = -parseInt(this.type2.substr(3, 2))
            if (tz >= 0) {
              tz = '+' + tz
            }
            result = format(utcToZonedTime(result, 'Etc/GMT' + tz.toString()), 'yyyy-MM-dd HH:mm:ss') +
              ` <span class="tip timezone">${this.type2}</span>`
          }
        } else if (this.type1 === 'number') {
          if (this.type2 === 'beusPlan') {
            if (result === 'pvCharge') {
              result = '<i class="bi-beus"></i> PV 충전'
            } else {
              if (this.beusPlanData[result]) {
                result = `<span class="plan${this.beusPlanData[result].bFree ? ' free' : ''}"><i class="bi-beus white"></i>
                ${this.beusPlanData[result].planName} <span class="tip plan-number">${result}</span></span>`
              }
            }
          } else if (this.type2 === 'baPlan') {
            if (result === 'pvCharge') {
              result = '<i class="bi-ba"></i> PV 충전'
            } else {
              if (this.baPlanData[result]) {
                result = `<span class="plan${this.baPlanData[result].bFree ? ' free' : ''}"><i class="bi-ba white"></i>
                ${this.baPlanData[result].planName} <span class="tip plan-number">${result}</span></span>`
              }
            }
          } else if (this.type2 === 'count') {
            result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if (this.type2 === 'price') {
            result = '<i class="xi-won"></i>' + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if (this.type2 === 'percent') {
            result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'
          } else if (this.listData) {
            for (const item of this.listData) {
              if (item.value === result) {
                result = item.name
              }
            }
          }
        } else if (this.type1 === 'boolean') {
          if (this.type2 === 'success') {
            if (result === true) {
              result = '<span class="bullet green"></span> 성공'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 실패'
            }
          } else if (this.type2 === 'distribute') {
            if (result === true) {
              result = '<span class="bullet green"></span> 배포'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 미배포'
            }
          } else if (this.type2 === 'planState') {
            if (result === true) {
              result = '<span class="bullet green"></span> 적용 중'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 지난 플랜'
            }
          } else {
            if (result === true) {
              result = '<span class="bullet green"></span> 예'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 아니오'
            }
          }
        } else if (this.type1 === 'image') {
          if (this.type2 === 's3') {
            if (result) {
              const [bucketName, filePath] = result.split(':')
              result = `<img src="https://s3.ap-northeast-2.amazonaws.com/${bucketName}/${filePath}" alt="">`
            }
          }
        }
      } else {
        if (this.type1 === 'datetime') {
          if (this.type3 === 'NA') {
            result = '<span>N/A</span>'
          }
        } else {
          if (this.type2 === 'state') {
            if (result === null) {
              result = 0
            }
          }
        }
      }

      return result
    }
  },
  methods: {

    selectText (element) {
      let range
      if (document.selection) {
        range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
      } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
    },
    copy () {
      this.selectText(this.$refs.dataUi)
      document.execCommand('copy')
      toastWarning('복사했습니다')
    },
    setTableData (idx, field, data) {
      this.$emit('setTableData', idx, field, data)
    },
    button (value, data) {
      this.$emit('button', value, data)
    },
    changeTableSort (sort) {
      this.$emit('changeTableSort', sort)
    },
    buttonAction (value, data) {
      const dataId = data.id
      window.open(FORUM_HOME_URL + dataId, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
div.ui-data {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    box-shadow: inset 0 0 10px 10px $gray;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px $gray;
  }
}

.text-button {
  display: flex;
  justify-content: space-between
}
.button-with-text {
  display: contents;
}
.content-text {
  padding-top: 3px;
  padding-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-multiline {
  .ui-multiline-line {
    white-space: nowrap;
    padding: 5px 0;
    border-bottom: 1px solid $dim;

    .ui-data {
      display: block;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.ui-data {
  &.type1number {
    text-align: right;
  }

  &.type2imageupload {
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  &.type1datetime,
  &.type1image,
  &.type2state,
  &.type2card,
  &.type2type,
  &.type2lang {
    text-align: center;
  }

  &.type2service,
  &.type2beusPlan,
  &.type2baPlan {
    text-align: left;
  }

  &.type2count,
  &.type2price,
  &.type2percent {
    font-family: 'Noto Sans Mono', Monospaced;
    letter-spacing: -1px;
    overflow: visible !important;

    & :deep .xi-won {
      float: left;
      margin: 2.5px 2px 0 0;
    }
  }

  &.type2price {
    min-width: 83px;
  }

  &.type2code {
    margin-top: -11px !important;

    & :deep .code {
      padding-bottom: 11px;
    }
  }

  &.center {
    max-width: inherit !important;
    text-align: center;
  }

  & :deep {
    a {
      color: inherit;
      border-bottom: 1px solid $light;
      transition: border-bottom-color 0.3s ease;

      &:hover {
        border-bottom-color: $gray;
      }
    }

    i[class^=xi-] {
      color: $purple;
    }

    img {
      max-width: 100%;
    }

    .code {
      display: block;
      overflow: auto;
      background: $darkgray;
      color: $white;
      padding: 10px 13px;
      border-radius: 10px;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border: 3px solid transparent;
        box-shadow: inset 0 0 10px 10px $gray;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px $gray;
      }

      pre {
        font-family: 'Noto Sans Mono', Monospaced;
        width: 100%;
        line-height: 1.7;
        font-size: 0.8em;
      }
    }

    .static,
    .match {
      padding: 1px 4px;
      font-family: 'Noto Sans Mono', Monospaced;
      border-radius: 3px;
      background: $dimlight;
    }

    .plan {
      display: inline-block;
      vertical-align: middle;
      background: $purple;
      color: $white;
      border-radius: 10px;
      padding: 3px 4px 3px 7px;
      line-height: 1;

      &.free {
        background: $gray;
        opacity: 0.7;
      }

      i {
        transform: translateY(-0.1em);
      }

      .tip {
        color: $gray;
        background: $white;
      }
    }

    .state {
      display: inline-block;
      vertical-align: middle;
      background: $gray;
      background-size: 100% 100%;
      color: $white;
      border-radius: 10px;
      padding: 3px 7px;
      font-size: 0.9em;
      line-height: 1.11em;

      &.red {
        background-color: $red;
      }

      &.blue {
        background-color: $blue;
      }

      &.green {
        background-color: $green;
      }

      &.yellow {
        background-color: $orange;
      }

      &.purple {
        background-color: $purple;
      }

      &.dim {
        opacity: 0.7;
      }
    }

    .method {
      &.card {
        i {
          color: $orange;
        }
      }

      &.wire {
        i {
          color: $green;
        }
      }

      &.coupon {
        i {
          color: $pink;
        }
      }

      &.admin {
        i {
          color: $red;
        }
      }
    }

    .bullet {
      display: inline-block;
      vertical-align: middle;
      background: $gray;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      transform: translateY(-1px);

      &.green {
        background: $grass;
      }

      &.red {
        background: $red;
      }
    }

    .tip {
      display: inline-block;
      vertical-align: middle;
      background: $gray;
      color: $white;
      border-radius: 10px;
      font-size: 70%;
      padding: 2px 4px;
      transform: translateY(-1px);
      line-height: 1;

      &.timezone {
        background: $gray;
        opacity: 0.7;
      }
    }
  }
}
</style>
