<template>
  <div class="template-search-list"
       :class="{ searching: show.search }">
    <div class="top-area">
      <slot></slot>
      <div class="local-block" v-if="$route.name === 'Solomon'">
        <ul>
          <li class="item__language">
            <span class="item item-language">언어</span>
            <div class="language--wrap"
                id="languageSelect">
              <span class="current-language"
                    @click.stop.prevent="showLanguageList(true)">
                <i class="bi-icon--lang"
                  :class="locale"></i>
                {{ locale.toUpperCase() }}
              </span>
              <i class="bi-icon--drop-down"></i>
              <transition name="slide">
                <ul class="language__list" :class="{'is-show': showLanguageListStatus}" v-show="showLanguageListStatus">
                  <li v-for="item in localeData" :key="item.code"
                      :class="{ [item.code]: true, on: item.code === locale }"
                      :title="item.title"
                      @click="onLocaleClick(item.code)">
                    <i class="bi-icon--lang" :class="item.code"></i> {{ item.code.toUpperCase() }}
                  </li>
                </ul>
              </transition>
            </div>
          </li>
        </ul>
      </div>
      <ui-button :color="'blue'"
                 @click="toggleSearch">
        <i class="xi-search"></i> 검색
      </ui-button>
    </div>

    <div class="table-area">
      <ui-table-list v-if="columnData && listData"
                     :columnData="columnData"
                     :listData="listData"
                     :navigation="navigationOption"
                     :sortBy="sortBy"
                     :columnFixed="columnFixed"
                     :buttonOption="buttonOption"
                     @buttonAction="buttonAction"
                     @goPage="goPage"
                     @changePageSize="changePageSize"
                     @changeSort="changeSort" />
    </div>

    <div class="search-area">
      <ui-filter v-if="searchOption"
                 :searchOption="searchOption"
                 :searchFilter="searchFilter.current"
                 @updateFilter="updateFilter"
                 @search="search" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'

import { EventBus } from '@/utils/event-bus'

import { alertError } from '@/utils/tools'

import UiFilter from '@/components/_ui/UiFilter'
import UiTableList from '@/components/_ui/UiTableList'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'TemplateSearchList',
  components: {
    UiFilter,
    UiTableList,
    UiButton
  },
  props: {
    listApi: {},
    defaultSortBy: {
      type: String
    },
    buttonOption: {
      type: Array,
      default () {
        return []
      }
    },
    initialSearchFilter: {
      type: Object,
      required: false
    },
    reload: {
      required: false
    },
    excelDownload: {
      required: false,
      default: false
    },
    searchUpdated: {
      required: false,
      default: true
    }
  },
  created () {
    this.initFilter()
    this.loadData(true, null)
    this.localeData = [
      {
        code: 'ja',
        title: 'Japanese'
      },
      {
        code: 'en',
        title: 'English'
      },
      {
        code: 'ko',
        title: 'Korean'
      }
    ]

  },
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  watch: {
    reload () {
      this.loadData()
    },
    locale (value) {
      this.loadData(true, value)
    }
  },
  methods: {
    onLocaleClick (locale) {
      store.commit('setLocale', locale)
      this.showLanguageList(false)
    },
    showLanguageList (status) {
      status ? this.showLanguageListStatus = true : this.showLanguageListStatus = false
    },
    initFilter () {
      if (this.initialSearchFilter) {
        for (const [key, value] of Object.entries((this.initialSearchFilter))) {
          this.searchFilter.current[key] = value
          this.searchFilter.previous[key] = value
        }
      }
    },
    loadData (initial = false, lang = null) {
      EventBus.$emit('onLoading', true)
      this.listApi({
        pageNum: this.navigationOption.pageNum,
        pageSize: this.navigationOption.pageSize,
        sortBy: this.sortBy,
        searchFrom: this.searchFilter.previous,
        lang: this.locale
      }, true).then(response => {
        if (typeof response.result !== 'object' || response.message) {
          alertError('목록을 불러오지 못 했습니다.', typeof response.result !== 'object' ? response.result : response.message)
        } else {
          this.listData = response.result.list
          this.columnData = response.result.meta.column

          Object.entries(this.columnData).forEach(([key, value], index) => {
            if (value.columnFixed) {
              this.columnFixed = index
            }
          })

          if (this.searchUpdated || !this.searchOption) {
            this.searchOption = response.result.meta.searchOption
          } else {
            // this.searchFilter.current = {}
          }

          this.navigationOption.totalCount = response.result.count
          this.$emit('searchFilter', this.searchFilter.previous)
        }
      }).catch(error => {
        if (error.response) {
          alertError('목록을 불러오지 못 했습니다.', typeof error.response.data !== 'object' ? error.response.data : error.response.data.message)
        } else {
          alertError('목록을 불러오지 못 했습니다.', error)
        }
      }).finally(() => {
        EventBus.$emit('onLoading', false)
      })
    },

    goPage (no) {
      this.navigationOption.pageNum = no
      this.loadData()
    },
    changeSort (key) {
      this.sortBy = key
      this.navigationOption.pageNum = 1
      this.loadData()
    },
    changePageSize (pageNum, pageSize) {
      this.navigationOption.pagesize = pageSize
      this.navigationOption.pageNum = pageNum
      this.loadData()
    },

    buttonAction (event, params) {
      params.event = event
      this.$emit(event, params)
    },

    toggleSearch () {
      this.show.search = !this.show.search
    },

    updateFilter (filter, isAll = false) {
      if (isAll) {
        this.searchFilter.current = {}
        this.searchFilter.previous = {}
        this.initFilter()
      } else {
        this.searchFilter.current = filter
      }

      const prev = this.searchFilter.previous
      const current = this.searchFilter.current

      if (current.step1Name && current.step1Name !== prev.step1Name) {
        prev.step1Name = current.step1Name
        if (this.searchOption.step1Name.options) {
          this.searchOption.step1Name.options.forEach(item => {
            if (item.value === filter.step1Name) {
              delete this.searchFilter.current.step2Name
              delete this.searchFilter.current.step3Name
              this.searchOption.step2Name.options = []
              this.searchOption.step2Name.options = item.children
              if (this.searchOption.step2Name.options[0].value !== 0) {
                this.searchOption.step2Name.options.unshift({
                  name: '선택안함',
                  value: 0
                })
              }

              this.searchOption.step3Name.options = []
              // this.searchOption.step3Name.options = item.children
              // if (this.searchOption.step3Name.options[0].value !== 0) {
              //   this.searchOption.step3Name.options.unshift({
              //     name: '선택안함',
              //     value: 0
              //   })
              // }
            }
          })
        }
      }


      if ((current.step2Name || current.step2Name >= 0) && current.step2Name !== prev.step2Name) {
        prev.step2Name = current.step2Name

        delete this.searchFilter.current.step3Name
        this.searchOption.step3Name.options = []
        if (this.searchOption.step2Name.options) {
          this.searchOption.step2Name.options.forEach(item => {
            if (item.value === filter.step2Name) {
              this.searchOption.step3Name.options = item.children
              if (this.searchOption.step3Name.options[0].value !== 0) {
                this.searchOption.step3Name.options.unshift({
                  name: '선택안함',
                  value: 0
                })
              }
            }
          })
        }
      }
    },
    search () {
      this.sortBy = this.defaultSortBy
      this.navigationOption = {
        pageNum: 1,
        pageSize: 50,
        totalCount: 0
      }
      this.searchFilter.previous = { ...this.searchFilter.current }

      this.loadData()
    }
  },
  data () {
    return {
      loading: false,
      columnFixed: null,
      columnData: null,
      listData: null,
      sortBy: this.defaultSortBy,
      navigationOption: {
        pageNum: 1,
        pageSize: 50,
        totalCount: 0
      },

      searchOption: null,
      searchFilter: {
        previous: {},
        current: {}
      },

      selectedUserNum: null,
      show: {
        search: true,
        userCreate: false,
        userDetail: false
      },
      showLanguageListStatus: false
    }
  }
}
</script>

<style lang="scss" scoped>
.template-search-list {
  .top-area {
    z-index: 20;
    position: absolute;
    top: 20px;
    right: 10px;

    & > .local-block{
      z-index: 40;
      position: absolute;
      top: -15px;
      right: 70px;
      & > ul {
        width: 190px;
        padding: 0 10px;
        box-sizing: border-box;

        li {
          padding: 7px 10px;
          box-sizing: border-box;
          position: relative;
          background-color: #ffffff;

          &.item__language {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 12px 0px 0px 0px;
          }

          &.divlist {
            padding: 0px 10px;
          }

          .item {
            display: inline-block;
            width: 100%;
            height: 27px;
            line-height: 27px;
            font-size: 14px;
            color: #2f2f2f;
            padding-left: 10px;
            box-sizing: border-box;
            border-radius: 12px;
            text-align: left;
            transition: background-color 0.2s ease;

            &.disabled {
              cursor: default;
              opacity: 0.4;
            }
          }

          &:hover .item:not(.item-language):not(.disabled):not(.signout) {
            background-color: #ebebeb;
          }

          &.item--common {
            padding: 0px;
            padding-top: 7px;
            margin: 7px 10px;
            border-top: 1px dashed #d1d1d1;
          }

          .signout {
            display: block;
            margin: 0 auto;
            width: 180px;
            height: 34px;
            border-radius: 20px;
            border: solid 1px #bbbbbb;
            background-color: #ffffff;
            padding-left: 0;
            text-align: center;
            line-height: 32px;
          }

          .language--wrap {
            position: absolute;
            top: 12px;
            right: 0;
            width: 98px;
            height: 38px;
            border-radius: 6px;
            border: solid 1px #bbbbbb;
            background-color: #ffffff;
            box-sizing: border-box;

            .current-language {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              width: 100%;
              height: 100%;
              line-height: 36px;
              padding-left: 6px;
              box-sizing: border-box;

              font-size: 14px;
              color: #2f2f2f;
              cursor: pointer;
              .icon--lang {
                vertical-align: middle;
                margin-right: 5px;
                position: relative;
                top: -1px;
              }
            }
            .language__list li{
              display: inline-block;
              width: 100%;
              height: 100%;
              line-height: 36px;
              padding-left: 6px;
              box-sizing: border-box;

              font-size: 14px;
              color: #2f2f2f;
              cursor: pointer;

              .icon--lang {
                vertical-align: middle;
                margin-right: 5px;
                position: relative;
                top: -1px;
              }
            }

            .language__list {
              width: 98px;
              border-radius: 6px;
              box-shadow: 0 23px 15px -12px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.25);
              background-color: #ffffff;
              overflow: hidden;
              position: absolute;
              top: -1px;
              left: -1px;
              z-index: 1;

              opacity: 0;
              transform-origin: top;
              transition: transform .2s ease, opacity .1s ease;

              &.is-show {
                opacity: 1;
              }

              li {
                transition: background-color 0.2s ease;

                &:hover {
                  background-color: #ebebeb;
                }
              }
            }

            .bi-icon--drop-down {
              pointer-events: none;
              position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%);
            }
          }

          div {
            padding-left: 13px;
            color: #444444;
            border-radius: 20px;
            cursor: pointer;
            font-size: 13px;
            height: 27px;
            line-height: 27px;
            &:hover {
              background-color: #ebebeb;
            }
          }
          div::before {
            content: "•";
            color: #999999;
          }
        }
      }
    }
  }

  .table-area {
    margin-right: 0;
    transition: margin-right 0.3s ease;
  }

  .search-area {
    /* z-index: 30; */
    position: fixed;
    bottom: 0;
    right: -260px;
    width: 250px;
    height: calc(100vh - 71.64px);
    margin-left: 200px;
    background-color: $dimlight;
    transition: right 0.3s ease;
  }

  &.searching {
    .table-area {
      margin-right: 250px;
    }

    .search-area {
      right: 0;
    }
  }
}
</style>
