import { EventBus } from '@/utils/event-bus'

import isobject from 'isobject'
import nacl from 'tweetnacl'
import naclUtil from 'tweetnacl-util'

export const BEUS_URL = process.env.VUE_APP_BEUS_URL || 'https://tool.beusable.net'
export const BA_URL = process.env.VUE_APP_BA_URL || 'https://analytics.beusable.net'
export const BEUS_HOME_URL = process.env.VUE_APP_BEUS_HOME_URL || 'https://www.beusable.net'
export const BA_HOME_URL = process.env.VUE_APP_BA_HOME_URL || 'https://analytics.beusable.net'
export const SSBEUS_URL = process.env.VUE_APP_SSBEUS_URL || 'https://samsung-tool.beusable.net'

export function isPlainObject (value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function hasKey (obj, keys) {
  for (const key of keys) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }
  return true
}

export function isEmptyObject (obj) {
  return isobject(obj) && Object.keys(obj).length === 0
}

export function isEmptyValue (value) {
  return value === undefined || value === null || isEmptyObject(value)
}

export function alertError (content, error) {
  let message = error || '알 수 없는 에러'
  if (error && error.response) {
    message = error.response.data.message || ''
  }
  if (isobject(message)) {
    message = ''
  }
  alert(`${content}\n${message}`)
}

export function toastWarning (message) {
  EventBus.$emit('toastWarning', message)
}

export function isEndWithConsonant (korStr) {
  const finalChrCode = korStr.charCodeAt(korStr.length - 1)
  const finalConsonantCode = (finalChrCode - 44032) % 28
  return finalConsonantCode !== 0
}

export function isIf (target, option, value) {
  if (option === '=') {
    return !(target === value)
  } else if (option === '>') {
    return !(target > value)
  } else if (option === '<') {
    return !(target < value)
  } else if (option === '<=') {
    return !(target <= value)
  } else if (option === '>=') {
    return !(target <= value)
  } else if (option === '!=') {
    return !(target !== value)
  } else {
    return false
  }
}

export function encrypt (data, key) {
  function padEnd (string, targetLength, padString) {
    targetLength = targetLength >> 0
    padString = String((typeof padString !== 'undefined' ? padString : ' '))
    if (string.length > targetLength) {
      return string.slice(0, 32)
    } else {
      targetLength = targetLength - string.length
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length)
      }
      return string + padString.slice(0, targetLength)
    }
  }

  function textEncode (str) {
    if (window.TextEncoder) {
      return new TextEncoder('utf-8').encode(str)
    }
    const utf8 = unescape(encodeURIComponent(str))
    const result = new Uint8Array(utf8.length)
    for (let i = 0; i < utf8.length; i++) {
      result[i] = utf8.charCodeAt(i)
    }
    return result
  }

  const encodeBase64 = naclUtil.encodeBase64
  const nonce = nacl.randomBytes(24)
  const secretKey = textEncode(padEnd(key, 32, '0'))
  const secretData = textEncode(data)
  const encrypted = nacl.secretbox(secretData, nonce, secretKey)
  return `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`
}

export function leadingZero (number) {
  return number < 10 ? '0' + number : number
}
