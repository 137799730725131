import { request } from '@/utils/api-request'
import { encrypt } from '@/utils/tools'

export function signIn (params) {
  const url = '/api/admin/user/signin'
  const newParams = {
    ...params,
    password: encrypt(params.password, params.userId)
  }

  const config = {
    method: 'post',
    url,
    data: newParams
  }
  return request(config, true)
}

export function getUserList (params) {
  const url = '/api/admin/user/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserInfo (params) {
  const url = '/api/admin/user/info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editUserInfo (params) {
  const url = '/api/admin/user/edit'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteUser (params) {
  const url = '/api/admin/user/delete'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserExcelMeta (force) {
  const url = '/api/admin/user/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}
