import { request } from '@/utils/api-request'

export function getCategoryList (params) {
  const url = '/api/admin/category/list'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getFrontCategoryList (params) {
  const url = '/api/category/list'

  const config = {
    method: 'post',
    url,
    data: {}
  }
  return request(config, true)
}

export function updateCategoryList (params) {
  const url = '/api/admin/category/update'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

// solomon
export function getSolomonCategoryList (params) {
  const url = '/api/admin/category/solomonList'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

// export function getSolomonFrontCategoryList (params) {
//   const url = '/api/category/list'

//   const config = {
//     method: 'post',
//     url,
//     data: {}
//   }
//   return request(config, true)
// }

export function updateSolomonCategoryList (params) {
  const url = '/api/admin/category/solomonUpdate'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
