import { request } from '@/utils/api-request'

export function getSolomonInfo (params) {
  const url = '/api/admin/solomon/info'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateCategoryList (params) {
  const url = '/api/admin/category/update'

  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
