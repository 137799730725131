<template>
  <div>
    <transition name="fade">
      <div class="popup-preview" v-if="excelDownload">
        <div class="popup">
          <i class="close-button xi-close"
             @click="close"></i>
          <h4 class="title"></h4>
          <div class="content">
            <ui-form v-if="columnList" :meta="columnList"
                     :data="model"
                     @set="setData"
                     @input="input"
                     class="selectcheck">
              <h4 class="title">엑셀 컬럼 선택</h4>
              <ui-button :color="'gray'"
                         @click="selectAll(true)">
                <i class="xi-check-square-o"></i> 전체 선택
              </ui-button>
              <ui-button :color="'gray'"
                         @click="selectAll(false)">
                <i class="xi-checkbox-blank"></i> 전체 해제
              </ui-button>
              <ui-button :color="'red'"
                         @click="excelExport">
                <i class="xi-download"></i> 다운로드
              </ui-button>
            </ui-form>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import UiForm from '@/components/_ui/UiForm'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'ExcelColumnSelect',
  components: {
    UiForm,
    UiButton
  },
  props: {
    excelDownload: {
      required: true
    },
    metaApi: {
      required: true
    },
    listApi: {
      required: true
    },
    apiParam: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    searchFilter: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  computed: {
  },
  watch: {
    excelDownload () {
      if (this.excelDownload) {
        this.columnList = null
        this.model = {}
        this.loadMataData()
      }
    }
  },
  methods: {
    selectAll (isAllCheck) {
      for (const [key, value] of Object.entries(this.model)) {
        const listData = value || this.columnList.column.options
        for (let i = 0, iLen = listData.length; i < iLen; i++) {
          const option = listData[i]
          if (option.disabled !== true) {
            option.value = isAllCheck
          }
        }
        this.model[key] = listData
        this.$set(this.model, key, listData)
      }
    },
    setData (data) {
      if (this.columnList) {
        for (const [field, option] of Object.entries(this.columnList)) {
          const key = option.field || field
          if (key.includes('.')) {
            const splitedKey = key.split('.')
            const key1 = splitedKey[0]
            const key2 = splitedKey[1]
            if (data[key1] && data[key1][key2] !== undefined) {
              this.$set(this.model, key, data[key1][key2])
            } else if (option.static) {
              this.$delete(this.model, key)
            }
          } else {
            if (data[key] !== undefined) {
              this.$set(this.model, key, data[key])
            } else if (option.static) {
              this.$delete(this.model, key)
            }
          }
        }
      }
    },
    input (field, value) {
      this.$set(this.model, field, value)
    },
    close () {
      this.columnList = null
      this.model = {}
      this.$emit('close')
    },
    loadMataData (callback = null) {
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.metaApi(this.apiParam, true).then(response => {
          this.columnList = response.result
        }).catch(error => {
          alertError('상세 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    excelExport () {
      const colList = []
      for (const [key, value] of Object.entries(this.model)) {
        for (let i = 0, iLen = value.length; i < iLen; i++) {
          const option = value[i]
          if (option.value && key) {
            colList.push(option.field)
          }
        }
      }

      if (colList.length > 0) {
        EventBus.$emit('onLoading', true)
        this.apiParam.searchFrom = this.searchFilter
        this.apiParam.excelDownload = true
        this.apiParam.excelColumnList = colList
        this.apiParam.sortBy = '-createdAt'
        setTimeout(() => {
          this.listApi(this.apiParam, true).then(response => {
            const list = response.result.list
            const column = response.result.meta.column.column.options
            this.$emit('export', column, list, colList)
          }).catch(error => {
            alertError('상세 데이터를 불러오지 못 했습니다.', error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
          })
        }, 300)
      } else {
        alert('최소 한개이상의 컬럼을 선택해주세요.')
      }
    }
  },
  data () {
    return {
      columnList: null,
      model: {}
    }
  }
}
</script>

<style lang="scss" scoped>

.popup-preview {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 9999999;
  overflow-y: hidden;

  & :deep {
    .form {
      .title {
        color: #000 !important;
      }

      .form-content {
        height: 400px !important;
      }
    }
  }

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //height: calc(100% - 30px);
    //width: calc(100% - 60px);
    width: 500px;
    padding: 0 30px 30px;
    background: $black;

    .close-button {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 55;
      background-color: $dark;
      color: $white;
      border-radius: 20px;
      padding: 3px;
      cursor: pointer;
      font-size: 12px;
      transition: background-color 0.3s ease,
      color 0.3s ease;

      &:hover {
        background-color: $red;
        color: $white;
      }
    }

    .title {
      font-size: 16px;
      padding: 8px 2px;
      height: 16px;
      font-weight: bold;
      color: $white;

      i {
        margin: 1px 5px 0 0;
      }
    }

    .content {
      width: 100%;
      height: 500px;
      border-radius: 10px;
      transform-origin: left top;
      background-color: #fff;
      overflow: auto;

      .selectcheck {
        & :deep .form-content {
          .form-table {
              tr {
                :hover {
                  background: none;
                }
                td {
                  border-bottom: none;
                  background: none;
                  padding: 5px 0 0;
                }
              }
          }
        }
      }
    }
  }

  &.fade-leave-active,
  &.fade-enter-active {
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
}
</style>
